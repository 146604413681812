import React, {useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { defaultFirestore } from "../../Config/firebaseConfig";
import styles from "./MyProducts.module.css";
import {convertMillisToLocalTime} from "../../utils/globalFunctions";
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';


const MyProducts = () => {
  const [myAds, setMyAds] = useState([]);
  const [isProducts,setIsProducts] = useState(true);
    const history = useHistory();

  useEffect(  () => {
      getMyAds()
  }, []);

  const getMyAds = () => {
      const self = this;
      let myProducts = [];
       defaultFirestore
          .collection("ads")
          .where("owner", "==", localStorage.getItem("uid"))
          .get().then(myProductsSnapShot=>{
              myProductsSnapShot.forEach((doc) => {
                  myProducts.push(doc.data());
              });
              if(myProducts.length === 0){
                  setIsProducts(false)
              }
              setMyAds(myProducts)
          });
  };

  const deleteAd = (ad) =>{
      let confirm = window.confirm('You sure about to delete your product, Do you want to proceed?');
      if(confirm) {
          defaultFirestore.collection("ads").doc(ad.id).delete().then(() => {
              console.log("Document successfully deleted!");
              getMyAds()
          }).catch((error) => {
              console.error("Error removing document: ", error);
          });
      }

  };

  const listingToggle = (ad) =>{
      ad.isProductAvailable  = !ad.isProductAvailable ;
      defaultFirestore.collection("ads").doc(ad.id).set(ad).then(async function () {
           getMyAds()
      }).catch(function(error) {
          console.error("Error writing document: ", error);
          return false
      });
  };

  return (<div className={styles.myAdsWrapper} style={{maxWidth:1200,margin:'auto'}}>
          <div className='d-flex align-items-center justify-content-center'><h4 className='m-3' style={{color:"rgb(224,115,114)"}}>My Products</h4></div>
          {!isProducts && <div className='w-100 mt-5 d-flex flex-row align-items-center justify-content-center'><p>Products are not added yet!</p></div>}
      {myAds.map((ad) => {
        return (
          <div className={`d-flex align-content-center justify-content-center flex-row ${styles.myAdItem}`} onClick={()=>history.push(`/app/${ad.id}`)}>
              <img className='product-avatar m-auto' src={ad.media[0]}></img>
            <div className={`d-flex align-content-center justify-content-center flex-column ${styles.descriptionLayout}`}>
                <p className='mb-2 ml-2 bold' style={{color:"rgb(95,123,128)",fontWeight:"Bold"}}>{ad.title}</p>
                <p className={`mb-2 ml-2 ${styles.description}`} >{ad.description}</p>
            </div>
              <div style={{flex:1}}></div>
              <div className={styles.actions}>
                  <p className='mb-2 ml-2'>{convertMillisToLocalTime(ad.created_at)}</p>
                  <p onClick={(event => {
                      listingToggle(ad);
                      event.stopPropagation();
                  })} className={`${ad.isProductAvailable ? styles.listingButtonDisable : styles.listingButtonEnable }`}>{ad.isProductAvailable? "TURN OFF" +
                      " LISTING" : "TURN ON LISTING"}</p>
                  {/*<p className={styles.edit} onClick={(event)=> {*/}
                  {/*    event.stopPropagation();*/}
                  {/*    history.push(`/edit-ad/${ad.id}`)*/}
                  {/*}*/}
                  {/*}>Edit</p>*/}
                  <div className='d-flex flex-row justify-content-between'><div className={styles.edit}><Edit style={{width:25,height:20}}  onClick={(event)=> {
                      event.stopPropagation();
                      history.push(`/edit-ad/${ad.id}`)
                  }}/></div>
                  <div className={styles.delete}><Delete title={'Edit'} style={{width:25,height:20}}  onClick={(event)=> {
                      event.stopPropagation();
                      deleteAd(ad)
                  }}/></div>
                  </div>
              </div>
          </div>
        );
      })}
    </div>
  );
};

export default MyProducts;
