import React, {Component} from 'react';
import './recentMessageItem.scss'
import {addDefaultSrc, convertMillisToLocalTime} from "../../../utils/globalFunctions";

class RecentMessageItem extends Component {

    render() {
        const {chat} = this.props;
        return (
            <div className={`d-flex flex-row justify-content-between recent-message mb-1 recent-message-selected`} style={{background:chat.isRead? 'rgb(241,242,243)' :'#efefff'}}>
                <div className={`d-flex flex-row`}>
                    <img className='user-avatar-small mt-auto' onError={addDefaultSrc} src={chat?.adImage ? chat?.adImage :"https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"} alt={"profile"}/>
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                        <p className='mb-0 ml-2 ad-title' title={chat.adName}>{chat.adName}</p>
                        <p className='mb-0 ml-2'> {convertMillisToLocalTime(chat.created_at)}</p>
                    </div>
                </div>
                <div>
                    {/*<div className='recent-message-circle'>*/}
                    {/*</div>*/}
                    <p>{chat.senderName}</p>
                </div>


            </div>
        );
    }
}

export default RecentMessageItem;
