import React, {Component} from 'react';
import {defaultAuth, defaultDatabase} from "../../../Config/firebaseConfig";
import * as firebase from "firebase";
import {toastFeedback} from "../../../Toast/ToastFunctions";
import {saveUserProfile} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import google from '../../../Assets/images/btn_google_signin_light_pressed_web@2x.png'

class SignIn extends Component {

    signInWithEmail = () => {
        const self = this;
        defaultAuth.signInWithEmailAndPassword(this.state.email, this.state.password).then(response =>{
            let user = response.user;
            toastFeedback(`Welcome ${user.displayName}`);
            self.props.saveUserProfile({displayName:user.displayName,uid:user.uid,email:user.email});
            localStorage.setItem("displayName",user.displayName);
            localStorage.setItem("uid",user.uid);
            self.props.history.goBack();
        }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            toastFeedback(error.message);
        });
    };

    constructor(props, context) {
        super(props, context);
        this.state ={
            email:"",
            password:"",
            name:'',
            uid:"",
            isEmailVerified:false
        }
    }

    componentDidMount() {

    }

    onEmailChange = (event) =>{
        this.setState({email:event.target.value})
    };

    saveUserProfile = () => {
        const self = this;
        defaultDatabase.ref('users/' + this.state.uid).set({
            username: this.state.name,
            email: this.state.email,
            uid:this.state.uid
        },function (res) {
            localStorage.setItem("displayName",self.state.displayName);
            localStorage.setItem("uid",self.state.uid);
            self.props.history.goBack();
            self.props.saveUserProfile({displayName:self.state.name,uid:self.state.uid,email:self.state.email})

        });

    };

    onPasswordChange = (event) =>{
        this.setState({password:event.target.value})
    };

    onNameChange = (event) =>{
        this.setState({name:event.target.value})
    };

    onSignInClick = () => {
        this.signInWithEmail()
    };

    onSignInWithGoogle = () => {
        const  self = this;
        let provider = new firebase.auth.GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        firebase.auth().signInWithPopup(provider).then(function(result) {
            let token = result.credential.accessToken;
            let user = result.user;
            toastFeedback(`Welcome ${user.displayName}`);
            localStorage.setItem("displayName",user.displayName);
            localStorage.setItem("uid",user.uid);
            self.setState({email:user.email,name:user.displayName,uid:user.uid,isEmailVerified:true},function () {
                self.saveUserProfile()
            });
            self.props.history.push('/');
        }).catch(function(error) {
            let errorCode = error.code;
            let errorMessage = error.message;
            let email = error.email;
            let credential = error.credential;
        });
    };

    render() {
        return (
            <div className="d-flex flex-column align-items-center sign-up-container pt-4">
                {/*<div>*/}
                <div className='d-flex w-100 align-items-start justify-content-start'><p className='auth-header-text'>Log in</p></div>
                {/*<div className='text-left auth-inputs-container'>*/}
                {/*    <div className='d-flex w-100 flex-row align-items-start justify-content-start'><label className='mb-0'>Display name</label></div>*/}
                {/*    <input placeholder="Enter your display name" onChange={this.onNameChange} className='auth-inputs'/>*/}
                {/*</div>*/}

                <div className='text-left auth-inputs-container'>
                    <div className='d-flex w-100 flex-row align-items-start justify-content-start'><label className='mb-0'>Email</label></div>
                    <input placeholder="Enter your email" onChange={this.onEmailChange} className='auth-inputs'/>
                </div>
                <div className='text-left auth-inputs-container'>
                    <div className='d-flex w-100 flex-row align-items-start justify-content-start'><label className='mb-0'>Password</label></div>
                    <input placeholder="Enter your password" type={"password"} onChange={this.onPasswordChange} className='auth-inputs'/>
                </div>
                <div className='d-flex w-100 flex-row align-items-start justify-content-start'><p>Forgot password?</p></div>

                <div onClick={this.onSignInClick} className='cursor-pointer register-sign-in-button d-flex align-items-center justify-content-center pt-2 pb-2'><p className='mb-0'>Log In</p></div>

                <div className='sign-in-with-gmail-container mt-1 cursor-pointer'>
                    <div className='sign-in-with-gmail-button d-flex align-items-center justify-content-center mt-3' onClick={this.onSignInWithGoogle}><img src={google} style={{height:70,width:400}} className='mb-0'></img></div>
                </div>
                <div className='banner-action-mobile cursor-pointer'> <p className='mt-1 cursor-pointer' onClick={this.props.onActionChange}>Don't have an account? Register</p> </div>


                {/*</div>*/}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        categories:state.ads.categories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveUserProfile : (user) => {
            dispatch(saveUserProfile({user:user}))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)

