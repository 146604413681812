import React, {Component} from 'react';
import {defaultAuth, defaultDatabase} from "../../../Config/firebaseConfig";
import {toastFeedback} from "../../../Toast/ToastFunctions";
import * as firebase from "firebase";
import {createAd, getCategories, saveUserProfile} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import GoogleLogin from 'react-google-login';
import google from "../../../Assets/images/btn_google_signin_light_pressed_web@2x.png";

class SignUp extends Component {

    responseGoogle = (response) => {
        console.log(response);
    };


    registerWithEmail = () => {
        const self = this;
        defaultAuth.createUserWithEmailAndPassword(this.state.email, this.state.password).then(res=>{
            defaultAuth.currentUser.sendEmailVerification().then(function() {
                toastFeedback("Verification email sent your mail");
                console.log("current user", defaultAuth.currentUser.emailVerified);
            }, function(error) {
               console.log("error",error)
            });
            toastFeedback("Sign Up successful");
            res.user.updateProfile({
                displayName: self.state.name
            }).then(res=>{
                localStorage.setItem("displayName",user.displayName);
                localStorage.setItem("uid",user.uid);
                self.props.history.push('/app');
            });
            var user = res.user;
            self.setState({email:user.email,uid:user.uid,isEmailVerified:false},function () {
                self.saveUserProfile()
            });
        }).catch(function(error) {
            let errorCode = error.code;
            let errorMessage = error.message;
            if(errorCode === "auth/invalid-email"){
                toastFeedback("Please enter valid email");
            }
            else {
                toastFeedback(errorMessage);
            }
        });
    };

    constructor(props, context) {
        super(props, context);
        this.state ={
            email:"",
            password:"",
            name:'',
            isSignIn : false,
            uid:"",
            isEmailVerified:false
        }
    }

    onEmailChange = (event) =>{
        this.setState({email:event.target.value})
    };

    onPasswordChange = (event) =>{
        this.setState({password:event.target.value})
    };

    onNameChange = (event) =>{
        this.setState({name:event.target.value})
    };

    onRegisterClick = () => {
        this.registerWithEmail()
    };

    onSignInWithGoogle = () => {
        const self = this;
        let provider = new firebase.auth.GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        firebase.auth().signInWithPopup(provider).then(function(result) {
            let token = result.credential.accessToken;
            let user = result.user;
            self.setState({email:user.email,name:user.displayName,uid:user.uid,isEmailVerified:true});
            localStorage.setItem("displayName",user.displayName);
            localStorage.setItem("uid",user.uid);
            self.saveUserProfile()
            self.props.history.push('/')
        }).catch(function(error) {
            let errorCode = error.code;
            let errorMessage = error.message;
            let email = error.email;
            let credential = error.credential;
            toastFeedback(error.message);
        });
    };

    saveUserProfile = () => {
        const self = this;
        defaultDatabase.ref('users/' + this.state.uid).set({
            username: this.state.name,
            email: this.state.email,
            uid:this.state.uid
        },function (res) {
            localStorage.setItem("displayName",self.state.displayName);
            localStorage.setItem("uid",self.state.uid);
            self.props.history.goBack();
            self.props.saveUserProfile({displayName:self.state.name,uid:self.state.uid,email:self.state.email})

        });

    };

    render() {
        return (
            <div className="d-flex flex-column align-items-center sign-up-container pt-4">
                {/*<div>*/}
                <div className='d-flex w-100 align-items-start justify-content-start'><p className='auth-header-text text-left'>Register</p></div>
                <div className='text-left auth-inputs-container'>
                    <div className='d-flex w-100 flex-row align-items-start justify-content-start'><label className='mb-0'>User name</label></div>
                    <input placeholder="Enter your display name" onChange={this.onNameChange} className='auth-inputs'/>
                </div>

                <div className='text-left auth-inputs-container'>
                    <div className='d-flex w-100 flex-row align-items-start justify-content-start'><label className='mb-0'>Email</label></div>
                    <input placeholder="Enter your email" onChange={this.onEmailChange} className='auth-inputs'/>
                </div>
                <div className='text-left auth-inputs-container'>
                    <div className='d-flex w-100 flex-row align-items-start justify-content-start'><label className='mb-0'>Password</label></div>
                    <input placeholder="Choose a strong password" type={"password"} onChange={this.onPasswordChange} className='auth-inputs'/>
                </div>

                <div onClick={this.onRegisterClick} className='register-sign-in-button d-flex align-items-center cursor-pointer justify-content-center'><p className='mb-0'>Register</p></div>

                <div className='sign-in-with-gmail-container mt-1 cursor-pointer'>
                    <div className='sign-in-with-gmail-button d-flex align-items-center justify-content-center mt-3' onClick={this.onSignInWithGoogle}><img src={google} style={{height:70,width:400}} className='mb-0'></img></div>
                </div>
                {/*<div className='sign-in-with-gmail-container mt-1 mb-2 cursor-pointer'>*/}
                {/*    <div onClick={this.onSignInWithGoogle} className='sign-in-with-gmail-button d-flex align-items-center justify-content-center mt-3'><p className='mb-0'>Sign Up with Google</p></div>*/}
                {/*</div>*/}

                <div className='banner-action-mobile cursor-pointer'> <p className='mt-1 cursor-pointer ' onClick={this.props.onActionChange}>Have already an account? Login</p> </div>


                {/*<GoogleLogin*/}
                {/*    clientId="492596211439-1ihmf5p4adat8t63l8gb87m73dvdj12u.apps.googleusercontent.com"*/}
                {/*    buttonText="Login"*/}
                {/*    onSuccess={this.responseGoogle}*/}
                {/*    onFailure={this.responseGoogle}*/}
                {/*    cookiePolicy={'single_host_origin'}*/}
                {/*/>*/}
                {/*</div>*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categories:state.ads.categories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveUserProfile : (user) => {
            dispatch(saveUserProfile({user:user}))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)

