import React, {Component} from "react";
import {getAd} from "../../actions";
import connect from "react-redux/es/connect/connect";
import "react-image-gallery/styles/css/image-gallery.css";
import "./PostDetail.scss";
import '../../components/AdCard/AdCard.scss'
import CarouselView from "../../components/Carousal/CarouselView";
import {defaultAuth, defaultDatabase, defaultFirestore} from "../../Config/firebaseConfig";
import {toastFeedback} from "../../Toast/ToastFunctions";
import {NavLink, Switch} from "react-router-dom"
import Home from '@material-ui/icons/Home';
import Footer from "../../components/Footer/Footer";
import {CopyToClipboard} from "react-copy-to-clipboard";



class PostDetail extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            message:
                "Hi there,\n" +
                "\n" +
                "I'm interested in your item. Is this still available?" +
                "\n" +
                "\nThanks",
            images: [],
            isShareOpen : false,
            messageSent :false
        };
    }

    componentDidMount() {
        if (this.props.match) {
            this.props.getAd(this.props.match.params.adId);
        }

        defaultFirestore
            .collection("chats")
            .doc(
                "8D5z6SUpE5S3MNGQdQfzUWaht9OaXxVkocNhwMawvVibkZ02VFJ6BAeevsXvZpYtCKOWaLcNSiB2"
            )
            .collection("messages")
            .get()
            .then((snapshot) => {
                if (!snapshot.empty) {
                    let messages = [];
                    snapshot.forEach((doc) => {
                        messages.push(doc.data());
                    });
                }
            });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps.ad);
        if(nextProps.ad.media) {
            this.setState({images: nextProps.ad.media}, function () {
                console.log(this.state.images);
            });
        }
    }

     onShareClick = () => {

        console.log("navigator.share",navigator.share)
         if (navigator.share) {
             navigator.share({
                 title: 'Just play today',
                 text: 'Check out the product shared',
                 url: window.location.href,
             })
                 .then(() => console.log('Successful share'))
                 .catch((error) => console.log('Error sharing', error));
         }
         else{
             this.setState({isShareOpen:true})
         }
    };

    sendMessage = () => {
        let thisCopy = this
        console.log('user',this.props.user);
        if((this.props.user && this.props.user.uid )) {
            const self = this;
            let d = new Date();
            let messageTime = d.getTime();
            let messageId =
                this.props.ad.id + this.props.ad.owner + localStorage.getItem("uid");
            let adImage =
                this.props.ad.media && this.props.ad.media.length > 0
                    ? this.props.ad.media[0]
                    : "https://www.esa.int/var/esa/storage/images/esa_multimedia/videos/2018/05/mars_sample_return/17493376-1-eng-GB/Mars_sample_return_pillars.jpg";
            let messageRef = defaultDatabase
                .ref("chats")
                .child(messageId)
                .child("messages")
                .push();
            messageRef
                .set({
                    id: messageRef.key,
                    message: this.state.message,
                    receiver: self.props.ad.owner,
                    sentBy: localStorage.getItem("uid"),
                    created_at: messageTime,
                })
                .then(function () {
                    toastFeedback("Sent message successfully");
                    thisCopy.setState({messageSent:true},()=> {
                        console.log("messageSent",thisCopy.state.messageSent)
                    });
                    defaultDatabase
                        .ref("users/")
                        .child(localStorage.getItem("uid"))
                        .child("messages")
                        .child(messageId)
                        .set({
                            adId: self.props.ad.id,
                            adName: self.props.ad.title,
                            adImage: adImage,
                            message: self.state.message,
                            owner: self.props.ad.owner,
                            senderName: self.props.user.displayName,
                            subscriber: localStorage.getItem("uid"),
                            sentBy: localStorage.getItem("uid"),
                            receiver: self.props.ad.owner,
                            created_at: messageTime,
                            messageId: messageId,
                            isRead: false,
                        });


                    defaultDatabase
                        .ref("users/")
                        .child(self.props.ad.owner)
                        .child("messages")
                        .child(messageId)
                        .set({
                            adId: self.props.ad.id,
                            adName: self.props.ad.title,
                            adImage: adImage,
                            message: self.state.message,
                            owner: self.props.ad.owner,
                            senderName: self.props.user.displayName,
                            subscriber: localStorage.getItem("uid"),
                            sentBy: localStorage.getItem("uid"),
                            receiver: self.props.ad.owner,
                            created_at: messageTime,
                            messageId: messageId,
                            isRead: false,
                        });

                }).catch(function (error) {
                    console.error("Error writing document: ", error);
                    return false;
                })
        }else {
            toastFeedback("Please login to send message")
        }
    };

    render() {
        const { ad } = this.props;
        const { images } = this.state;
        return (
            <div>
                {this.state.isShareOpen && <div className="share-dialog is-open">
                    <header>
                        <h3 className="dialog-title">Share this pen</h3>
                        <button className="close-button" onClick={()=>this.setState({isShareOpen:false})}>
                            <svg>
                                <use href="#close"></use>
                            </svg>
                        </button>
                    </header>
                    <div className="targets">
                        <a href={`http://www.facebook.com/sharer/sharer.php?u=https://${window.location.href}&t=TITLE_HERE`} target={'_blank'} className="button">
                            <svg>
                                <use href="#facebook"></use>
                            </svg>
                            <span>Facebook</span>
                        </a>

                        <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target={'_blank'} className="button">
                            <svg>
                                <use href="#twitter"></use>
                            </svg>
                            <span>Twitter</span>
                        </a>

                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`} target={'_blank'} className="button">
                            <svg>
                                <use href="#linkedin"></use>
                            </svg>
                            <span>LinkedIn</span>
                        </a>

                        {/*<a href={'https://plus.google.com/share?url=www.justplaytoday.com'} target={'_blank'} className="button">*/}
                        {/*    <svg>*/}
                        {/*        <use href="#email"></use>*/}
                        {/*    </svg>*/}
                        {/*    <span>Email</span>*/}
                        {/*</a>*/}
                    </div>
                    <div className="link">
                        <div className="pen-url">{window.location.href}</div>
                        <button onClick={()=>{navigator.clipboard.writeText(window.location.href)}} className="copy-link">Copy Link</button>
                    </div>
                </div>}

                {/*<button className="share-button" type="button" title="Share this article">*/}
                {/*    <svg>*/}
                {/*        <use href="#share-icon"></use>*/}
                {/*    </svg>*/}
                {/*    <span>Share</span>*/}
                {/*</button>*/}

                <svg className="hidden">
                    <defs>
                        <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" className="feather feather-share">
                            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                            <polyline points="16 6 12 2 8 6"></polyline>
                            <line x1="12" y1="2" x2="12" y2="15"></line>
                        </symbol>

                        <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook">
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                        </symbol>

                        <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter">
                            <path
                                d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                        </symbol>

                        <symbol id="email" viewBox="0 0 24 24" fill="#777" stroke="#fafafa" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail">
                            <path
                                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                        </symbol>

                        <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin">
                            <path
                                d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect x="2" y="9" width="4" height="12"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </symbol>

                        <symbol id="close" viewBox="0 0 24 24" fill="red" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" className="feather feather-x-square">
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                        </symbol>
                    </defs>
                </svg>
                <div  className="d-flex align-items-center justify-content-center flex-column h-100" >
                <div style={{maxWidth:'1200px',marginTop:"60px",width:'100%'}} className="d-flex align-items-start justify-content-start  p-3">
                    <h3 style={{color:"black"}}>
                        Product Details
                    </h3>
                </div>
                <div style={{maxWidth:'1200px',color:'white'}} className="d-flex w-100 align-items-start justify-content-between">

                    <NavLink to="/app" className='drop-down-contentM d-flex align-items-center flex-row'>
                        <Home />
                        <div className=''>Home</div>
                    </NavLink>
                    <button onClick={this.onShareClick} style={{color:'black'}}>
                        <svg>
                            <use href="#share-icon"></use>
                        </svg>
                        Share</button>
                </div>
                <div className="past-detail-container">
                    <div className="post-carousal">
                        <CarouselView images={images} />
                    </div>
                    <div className="post-detail d-flex align-items-start justify-content-start flex-column p-2">
                        <div className="d-flex align-items-start justify-content-start flex-column w-100 more-details">
                            <div className="activeM"  >
                                {/*<p>Share</p>*/}
                                <p style={{color:"black",fontWeight:"Bold",fontSize:'24px'}}>
                                    {ad.title}
                                </p>
                            </div>
                            <div className="activeM">
                                <pre className="key mb-0">Price :  </pre>
                                <p className='ad-price'>  {`   ${ad.price}/-`}</p>
                            </div>
                            <div className="activeM">
                                <pre className="key mb-0">Category :  </pre>
                                <p className='ad-description'> {`   ${ad.category}/${ad.subCategory}`}</p>
                            </div>
                            <div className="activeM">
                                <pre className="key mb-0">Posted By :  </pre>
                                <p className='ad-description'> {`${ad.ownerName}`}</p>
                            </div>
                            <div className="activeM">
                                <pre className="key mb-0">Location :  </pre>
                                <p className='ad-description'>{ad.city} {ad.state}</p>
                            </div>
                            <div className="activeM">
                                <pre className="key mb-0">Condition :  </pre>
                                <p className='ad-description'>{ad.condition}</p>
                            </div>

                            {/*<div className='notActive'><p>Manufactured date : </p><p>{ad.mfd}</p></div>*/}
                            {/*<p>Manufactured date : {ad.mfd}</p>*/}
                            <div className="text-left activeM mt-3">
                                <p ><span className={"key"}>Description :</span> {ad.description}</p>
                            </div>
                        </div>
                        {console.log(localStorage.getItem("uid"),this?.props?.ad?.owner?.uid)}
                        {(localStorage.getItem("uid") !== this?.props?.ad?.owner  && !this.state.messageSent )&& <div><h4 className="mt-4" style={{color:"rgb(224,115,114)",fontWeight:'bold'}}>Contact seller</h4>
                            <div className="message-subject mt-2">
                               <textarea
                                   onChange={(event) =>
                                       this.setState({ message: event.target.value })
                                   }
                                   value={this.state.message}
                               />
                            </div>
                            <p className="text-left mt-2" style={{ fontFamily: "sans-serif",fontSize:14,background:'#d3d3d3',padding:'10px' }}>
                                Your contact details will be included in your reply. JustPlayToday
                                reserves the right to monitor conversations sent through our
                                servers to protect you from fraud, spam or suspicious behaviour.
                            </p>
                            {(!defaultAuth?.currentUser && !defaultAuth?.currentUser?.email && <a href={'/app/authentication'}>Login to send message or contact the seller</a> )}
                            {(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && !defaultAuth?.currentUser?.emailVerified) && <div>Please verify your email to send message !</div>}
                            {(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && defaultAuth?.currentUser?.emailVerified) && <button
                                className="btn btn-success mt-3 p-2"
                                onClick={this.sendMessage}
                            >
                                Send message
                            </button>}
                        </div>}
                        {this.state.messageSent && <p style={{color:'green'}}>Message sent successfully</p>}
                    </div>
                </div>
            </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ad: state.ads.ad,
        user:state.ads.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAd: (adId) => {
            dispatch(getAd({ adId }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDetail);
