import React, {Component} from 'react';
import Dashboard from "./pages/MainNav/Dashboard/Dashboard";
import SideNav from "./components/SideNav/SideNav";
import MainNav from "./pages/MainNav/MainNav";
import CreateAd from "./pages/CreateAd/CreateAd";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom"
import * as fireabase from "firebase";
import {saveUserProfile, updateCities} from "./actions";
import connect from "react-redux/es/connect/connect";
import PostDetail from "./pages/PostDetail/PostDetail";
import './styles/global.scss'
import Sample from "./components/Sample/Sample";
import {defaultAnalytics, defaultDatabase} from "./Config/firebaseConfig";
import Messages from "./components/messages/Messages";
import { hot } from 'react-hot-loader/root';
import EditAd from "./pages/CreateAd/EditAd";

const popularCities = ["All","Bangalore","Chennai","Mysore","Mangalore","Hyderabad","Vishakapatnam"];

class App extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      title :""
    }
  }

  componentDidMount() {

    defaultAnalytics.logEvent("App open")
    const self = this;
    if (window.navigator.geolocation) {
      window.navigator.geolocation
          .getCurrentPosition(console.log, console.log);
    }
    this.checkUserStatus();
    let cities = [];
    defaultDatabase.ref('IndianCities/').once('value',   function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        cities.push(childSnapshot.val());
      });
      const citiesData = [...popularCities,...cities];
      self.props.updateCitiesFunc(citiesData);
      self.setState({locations:citiesData})
    });
  }



  checkUserStatus = () => {
    const self = this;
    fireabase.auth().onAuthStateChanged(function(user) {
      if (user) {
        localStorage.setItem("displayName",user.displayName);
        localStorage.setItem("uid",user.uid);
        self.props.saveUserProfile({displayName:user.displayName,uid:user.uid,email:user.email})
        console.log(user)
      } else {
        localStorage.setItem("displayName","");
        localStorage.setItem("uid","");
      }
    });
  };

  render() {
    return (
        <div className="App">
          <Switch>
            <Route path="/" component={SideNav} exact/>
            <Route path="/app" component={SideNav}/>
            <Route path="/edit-ad/:adId" component={EditAd} exact/>
            <Route path="/post-ad" component={CreateAd} exact/>

          </Switch>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories:state.ads.categories
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserProfile : (user) => {
      dispatch(saveUserProfile({user:user}))
    },
    updateCitiesFunc : (cities) =>{
      dispatch(updateCities({cities:cities}))
    }
  }
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(App))

