import React, {Component} from 'react';
import {defaultAuth, defaultStorage} from "../../Config/firebaseConfig";
import SignUp from "./SignUp/SignUp";
import './Auth.scss'
import banner from '../../Assets/icons/pcqrK6Lc9.jpg'
import SignIn from "./SignIn/SignIn";
import {saveUserProfile} from "../../actions";
import {connect} from "react-redux";

class Auth extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isSignIn : false
        }
    }

    componentDidMount() {
        if(this.props.user && this.props.user.uid){
            this.props.history.push('/app')
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.user && nextProps.user.uid){
            this.props.history.push('/app')
        }
    }

    render() {
        return (
            <div className='auth_container d-flex align-items-center justify-content-center'>
                <div className='auth_div d-flex flex-row'>
                    <div className='left_banner d-flex flex-column align-items-center m-auto'>
                        <img  src={banner} className='banner-image'/>
                        <div className='banner-action cursor-pointer'>{this.state.isSignIn ? <p className='mt-3' onClick={()=>this.setState({isSignIn:false})}>Have already an account? Login</p> : <p className='mt-3' onClick={()=>this.setState({isSignIn:true})}>Don't have an account? Register </p>}</div>
                    </div>
                    <div className='right_banner m-auto'>
                        <div className='w-100'>
                            {this.state.isSignIn ? <SignUp history ={this.props.history} onActionChange={()=>this.setState({isSignIn:false})}/> : <SignIn history ={this.props.history} onActionChange={()=>this.setState({isSignIn:true})}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user:state.ads.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveUserProfile : (user) => {
            dispatch(saveUserProfile({user:{}}))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
