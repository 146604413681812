import React, {Component} from 'react';
import './ContactUs.css'
import Footer from "../../components/Footer/Footer";

class ContactUs extends Component {
    render() {
        return (
            <div >
                <div className='wrapper d-flex flex-column align-items-center justify-content-center'>
                <p className={'contactUsText'}>Contact us</p>
                <p className={'contactUsText'}>Email : info@justplaytoday.com</p>
            </div>
                <Footer/>
            </div>
        );
    }
}

export default ContactUs;
