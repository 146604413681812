import React, {Component} from 'react';

class PrivacyAndTerms extends Component {
    render() {
        return (
                   <pre style={{padding:10,paddingTop:90,lineHeight:2,width:'100%'}}>{`   TERMS AND CONDITIONS
 Welcome to JustPlayToday.com, a platform for buying and selling used music instruments. By using our website, you agree to be bound by the following terms and conditions.

By using our website, you agree to:
a. Only sell instruments that you have the legal right to sell.
b. Provide accurate and complete information about the instruments you are selling.
c. Not engage in fraudulent or deceptive practices, including misrepresenting the condition or history of the instruments you are selling.
d. Not post any content that is unlawful, defamatory, or harmful to minors.
e. Comply with all applicable laws and regulations, including those related to the sale of used goods.

2.User Conduct By using our website, you agree to:
a. Conduct all transactions in good faith.
b. Communicate respectfully and professionally with other users of our website.
c. Not engage in any behaviour that may harm or disrupt the functioning of our website.
d. Not engage in any behaviour that may compromise the security or integrity of our website.

3.Intellectual Property
All content on our website, including images, text, and logos, is owned by [website name] or our partners and is protected by copyright and other intellectual property laws. You may not use any of our content without our prior written consent.

4.Disclaimers and Limitations of Liability.
  a. We make no representations or warranties about the accuracy, completeness, or reliability of the information provided on our website.
  b. We are not responsible for any damages or losses that may result from the use of our website or the sale or purchase of instruments through our platform.
  c. We are not responsible for any actions taken by users of our website, including fraudulent or deceptive practices.
  d. We reserve the right to suspend or terminate your account if you violate these terms and conditions or engage in any unlawful or harmful behavior.`}</pre>
        );
    }
}

export default PrivacyAndTerms;
