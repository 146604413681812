import React, { Component } from 'react'
import "./AccountSettings.scss";
import CreateIcon from '@material-ui/icons/Create';
import {defaultAuth, defaultDatabase} from "../../../Config/firebaseConfig";
import {toastFeedback} from "../../../Toast/ToastFunctions";
import Footer from "../../../components/Footer/Footer";
export default class AccountSettings extends Component {

    state = {
        username: '',
        phoneNumber: '',
        email:"",
    };

    handelEmailChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    };

    componentDidMount() {
        toastFeedback("Loading .....");
        console.log("user",localStorage.getItem("uid"))
        const self = this
        let ref = defaultDatabase.ref('users').child(localStorage.getItem("uid"));
        ref.on("value", function(snapshot) {

            let user = snapshot.val();
            self.setState({username:user.username,email:user.email,phoneNumber:user.phoneNumber})
        }, function (error) {
            console.log("Error: " + error.code);
        });
    }

    resetPassword = (e) =>{
        e.preventDefault();
        console.log("email>>..",this.state.email)
        defaultAuth.sendPasswordResetEmail(this.state.email).then(function() {
            console.log("Reset email sent to your email");
            toastFeedback("Reset email sent to your email")
        }).catch(function(error) {
            console.log("Reset email failed",error)
            // An error happened.
        });
        console.log(this.state);
    };

    updateContactDetails = (e) =>{
        e.preventDefault();
        console.log(this.state);
    };

    changeContactDetails = (e) => {
        this.setState({
            [e.target.id]:e.target.value
        })
    };

    onUpdateUserProfile = () => {
        const {username,phoneNumber,email} = this.state;
        if(username.length >2 && phoneNumber.length ===10 ){
            defaultDatabase.ref('users/' + localStorage.getItem("uid")).set({
                username: this.state.username,
                phoneNumber:this.state.phoneNumber,
                email:email,
                uid:localStorage.getItem("uid"),
            },function () {
                toastFeedback("Updated your profile details")
            })
        }
        else{
            toastFeedback("please enter valid details")
        }
    };

    render() {
        const passModal = <div className="modal fade" id="passModal" tabindex="-1" role="dialog" aria-labelledby="passModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="passModalLabel">Reset password</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="row ">
                                <div className="form-group col-12 col-sm-12 col-md-6">
                                    <label for="newPassword">Enter your email *</label>
                                    <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.handelEmailChange} placeholder="Enter your email"/>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-12 col-sm-12 col-md-6">
                                    <button type="submit" onClick={this.resetPassword}  data-dismiss="modal" className="btn btn-primary " style={{width:"inherit"}}>Send reset email</button>
                                </div>
                            </div>
                            <p className="py-3">*required fields</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        const contactModal = <div className="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="contactModalLabel">Contact Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit = {this.updateContactDetails}>
                            <div className="row">
                                <div className="form-group col-12 col-sm-12 col-md-6">
                                    <label for="firstName">User Name:*</label>
                                    <input type="text" value={this.state.username} className="form-control" id="username" required onChange={this.changeContactDetails}/>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="form-group col-12 col-sm-12 col-md-6">
                                    <label for="contactNumber">Contact Number:</label>
                                    <input type="text" value={this.state.phoneNumber} className="form-control" id="phoneNumber" onChange={this.changeContactDetails}/>
                                </div>
                            </div>
                            * required fields
                            <div className="row py-3">
                                <div className="col-12 col-sm-12 col-md-6">
                                    <button type="submit" onClick={this.onUpdateUserProfile} data-dismiss="modal"className="btn btn-primary " style={{width:"inherit"}}>Save Changes</button>
                                </div>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </div>;
        return (
            <><div className="settings container">
                <h4> Account Settings</h4>
                <div className="settings-details container  ">
                    <h4 className="py-3">{this.state.username}</h4>
                    <div className="listWrapper">
                        <div className="d-flex justify-content-between pb-2">
                            <h5>Login Details</h5>
                            <span data-toggle="modal" data-target="#passModal" className="editIcon">Reset password</span>
                            {passModal}
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-6">
                                <h6>email:</h6>
                                <p>{this.state.email}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <h6>password:</h6>
                                <p>**********</p>
                            </div>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <div className="d-flex justify-content-between pb-2">
                            <h5>Contact Details</h5>
                            <span data-toggle="modal" className="editIcon" data-target="#contactModal"><CreateIcon style={{ fontSize: 18 }} />Edit</span>
                            {contactModal}
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-6">
                                <h6>Contact Number:</h6>
                                {!this.state.phoneNumber ? <p>add a phone number</p> : this.state.phoneNumber}
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <h6>Primary Email:</h6>
                                <p>{this.state.email}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <h6>User Name:</h6>
                                <p>{this.state.username}</p>
                            </div>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <h5 className="pb-2">Marketing Preferences</h5>
                        <div className="d-flex" style={{height:36}}>
                            <div className="btn-group btn-group-toggle toggleButton" data-toggle="buttons">
                                <label className="btn " style={{ width: "50px", height:"35px" }}>
                                    <input type="radio" name="options" id="option1" autocomplete="off" /> Off
                            </label>
                                <label className="btn active" style={{ width: "50px", height:"35px" }}>
                                    <input type="radio" name="options" id="option2" autocomplete="off" checked /> On
                            </label>
                            </div>
                            <p className="pl-2 mb-0 align-self-center">I would like to receive news, offers and promotions from Just Play Today.</p>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <h5 className="pb-2">Account</h5>
                        <p style={{color:"#5050f1", cursor:"pointer"}}>Deactivate your Account</p>
                    </div>
                </div>
            </div>
                <Footer/>
            </>
        )
    }
}
