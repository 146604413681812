import React from "react"
import "./ChatLeftCard.scss"
import {addProfileDefaultSrc, convertMillisToLocalTime} from "../../utils/globalFunctions"

const ChatLeftCard = ({ message,current_user }) => {
  return (
    <div className="chat-left-card-container d-flex align-self-start justify-content-start">
      <div className="chat-left-card-content d-flex">
          <img alt="profile_image" className="chat-left-card-profile" onError={addProfileDefaultSrc}
               src={"https://images.unsplash.com/photo-1554475900-0a0350e3fc7b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=959&q=80"}/>
          <div>
            <div className="chat-left-card-message-container">
              <p className='chat-left-card-message'>{message.message}</p>
            </div>
            <p className="chat-left-card-time-label"> {convertMillisToLocalTime(message.created_at)}</p>
          </div>
      </div>
      <div className="spacer"></div>
    </div>
  )
};

export default ChatLeftCard
