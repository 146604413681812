import React, {Component} from 'react';
import Back from '@material-ui/icons/ArrowBackIos';
import './Header.scss'

class Header extends Component {
    render() {
        return (
            <div className='d-flex align-items-center w-100 pl-2 pr-2 header-container'>
                <Back  className='cursor-pointer' onClick={()=>this.props.history.goBack()} style={{color:"white"}}/>
                <p onClick={()=>this.props.history.goBack()} className='header-text cursor-pointer'>Back</p>
                <div className='spacer'/>
                <div/>
            </div>
        );
    }
}

export default Header;
