import { call, put, takeLatest ,takeEvery} from "redux-saga/effects"
import {
    CREATE_AD,
    GET_AD, GET_AD_SUCCESS,
    GET_ADS,
    GET_ADS_SUCCESS,
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS, GET_FRESH_RECOMMENDATIONS, GET_KEYS, GET_KEYS_SUCCESS, SEARCH_ADS, SEARCH_ADS_SUCCESS
} from "../actions/constants";
import {
    createAdService,
    getAdService,
    getAdsService,
    getCategoriesService, getFreshRecommendationsService, searchAdsService,
    uploadAdMediaService
} from "../services/createAdService";
import {defaultDatabase, defaultFirestore} from "../Config/firebaseConfig";
import {toastFeedback} from "../Toast/ToastFunctions";
import {getKeys} from "eslint-visitor-keys";

function* createAd(action) {
    try {
        const response = yield call(createAdService,action.payload);
        if(action.payload.onCreateSuccess){
            action.payload.onCreateSuccess()
        }
    }
    catch (e) {
        console.log("error", e)
    }
}

function* getAds(action) {
    debugger
    try {
        const snapshot = yield call(getAdsService,action.payload);
        if(!snapshot.empty){
            let ads = [];
            snapshot.forEach(doc => {
                ads.push(doc.data())
            });
            action.payload.onSearchResults(ads);
            yield put({type: GET_ADS_SUCCESS,ads:ads})
        }
        else{
            yield put({type: GET_ADS_SUCCESS,ads:[]});
            toastFeedback("Results not found");
            if(action.payload.onSearchResults){
                action.payload.onSearchResults([]);
            }
        }
    }
    catch  (e) {
        yield put({type: GET_ADS_SUCCESS,ads:[]})
        console.log("error", e)
    }
}

function* searchAds(action) {
    debugger;
    try {
        const snapshot = yield call(searchAdsService,action.payload);
        debugger
        if(snapshot !== null) {
            if (!snapshot.empty) {
                let ads = [];
                snapshot.forEach(doc => {
                    ads.push(doc.data())
                });
                if (action.payload.onSearchResults) {
                    action.payload.onSearchResults(ads);
                }
                yield put({type: SEARCH_ADS_SUCCESS, ads: ads})
            } else {
                yield put({type: SEARCH_ADS_SUCCESS, ads: []});
                yield put({type: GET_ADS_SUCCESS, ads: []});
                toastFeedback("Results not found")
                if (action.payload.onSearchResults) {
                    action.payload.onSearchResults([]);
                }
            }
        }
        else{
            yield put({type: SEARCH_ADS_SUCCESS, ads: []});
            yield put({type: GET_ADS_SUCCESS, ads: []});
            toastFeedback("Results not found")
            if (action.payload.onSearchResults) {
                action.payload.onSearchResults([]);
            }
        }
    }
    catch (e) {
        yield put({type: GET_ADS_SUCCESS,ads:[]});
        console.log("error", e)
    }
}

function* getFreshRecommendations(action) {
    try {
        const snapshot = yield call(getFreshRecommendationsService,action.payload);
        var lastVisible = snapshot.docs[snapshot.docs.length - 1];
        console.log("lastVisible",action.payload);
        if(!action?.payload?.lastVisible){
            yield put({type: GET_ADS_SUCCESS,ads:[]});
        }
        if(!snapshot.empty){
            let ads = [];
            snapshot.forEach(doc => {
                ads.push(doc.data())
            });
            if(action.payload.onSearchResults){
                action.payload.onSearchResults(ads);
            }
            yield put({type: GET_ADS_SUCCESS,data:{ads:ads,lastVisible:lastVisible}})
        }
        else{
            yield put({type: GET_ADS_SUCCESS,ads:[]});
            if(action.payload.onSearchResults){
                action.payload.onSearchResults([]);
            }
            toastFeedback("Results not found")
        }
    }
    catch (e) {
        yield put({type: GET_ADS_SUCCESS,ads:[]})
        console.log("error", e)
    }
}

// function* getSearchKeys(action) {
//     try {
//         let keys =[];
//         defaultDatabase.ref('searchKeys/').once('value',   function(snapshot) {
//             snapshot.forEach(function(childSnapshot) {
//                 keys.push({key:childSnapshot.key,value:childSnapshot.val()});
//             });
//         });
//         yield put({type: GET_KEYS_SUCCESS,searchKeys:keys});
//     }
//     catch (e) {
//         yield put({type: GET_KEYS_SUCCESS,searchKeys:[]});
//         console.log("error", e)
//     }
// }



function* getAd(action) {
    try {
        const snapshot = yield call(getAdService,action.payload);
        ;
        if(!snapshot.empty){
            yield put({type: GET_AD_SUCCESS,ad:snapshot.data()})
        }
    }
    catch (e) {
        console.log("error", e)
    }
}

function* getCategories(action) {
    try {
        const response = yield call(getCategoriesService);
        if(response && response.exists)
            yield put({type: GET_CATEGORIES_SUCCESS,categories:response.data() })
    }
    catch (e) {
        console.log("error", e)
    }
}


export function* watchCreateAd() {
    yield takeLatest(CREATE_AD, createAd)
}

export function* watchUploadAdMedia() {
    yield takeEvery(CREATE_AD, createAd)
}

export function* watchGetCategories() {
    yield takeEvery(GET_CATEGORIES, getCategories)
}

export function* watchGetAds() {
    yield takeEvery(GET_ADS, getAds)
}

export function* watchSearchAds() {
    yield takeEvery(SEARCH_ADS, searchAds)
}

export function* watchGetFreshRecommendations() {
    yield takeEvery(GET_FRESH_RECOMMENDATIONS, getFreshRecommendations)
}

export function* watchGetSearchKeys() {
    // yield takeEvery(GET_KEYS, getSearchKeys)
}


export function* watchGetAd() {
    yield takeEvery(GET_AD, getAd)
}
