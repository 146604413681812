import { put, takeEvery, all } from "redux-saga/effects"
import {
    watchCreateAd,
    watchGetAd,
    watchGetAds,
    watchGetCategories,
    watchGetSearchKeys,
    watchUploadAdMedia,
    watchGetFreshRecommendations,
    watchSearchAds
} from "./createAdSaga";

export default function* rootSaga() {
    yield all([watchCreateAd(),watchUploadAdMedia(),watchGetCategories(),
        watchGetAds(),watchGetAd(),watchGetSearchKeys(),watchGetFreshRecommendations(),watchSearchAds()])
}
