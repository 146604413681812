import React from "react"
import Sidebar from "react-sidebar"
import MaterialTitlePanel from "./Material_title_panel"
import SidebarContent from "./Sidebar_content"
import "./NavBar.scss"
import pin from "../../Assets/icons/pin.svg"
import Menu from '@material-ui/icons/Menu';
import {NavLink, Switch} from "react-router-dom"
import {connect} from "react-redux"
import Dashboard from "../../pages/MainNav/Dashboard/Dashboard";
import AccountSettings from "../../pages/MainNav/AccountSettings/AccountSettings";
import Route from "react-router-dom/es/Route";
import Home from '@material-ui/icons/Home';
import Manage from '@material-ui/icons/FileCopy';
import Message from '@material-ui/icons/Message';
import MessageUnRead from '@material-ui/icons/MessageRounded'
import Create from '@material-ui/icons/PersonAdd';
import Logout from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import Phone from '@material-ui/icons/Phone';
import Info from '@material-ui/icons/Info';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Badge from '@material-ui/core/Badge';

import Auth from "../../pages/Auth/Auth";
import * as firebase from "firebase";
import {saveUserProfile} from "../../actions";
import {toastFeedback} from "../../Toast/ToastFunctions";
import logo from '../../Assets/images/JPTLogo.jpeg'
import Messages from "../messages/Messages";
import MyProducts from "../../pages/MyProducts/MyProducts";
import Favorites from "../../pages/Favorites/Favorites";
import PostDetail from "../../pages/PostDetail/PostDetail";
import {defaultAuth, defaultDatabase} from "../../Config/firebaseConfig";
import HelpAndContact from "../../pages/HelpAndContact/HelpAndContact";
import ContactUs from "../../pages/ContactUs/ContactUs";
import AboutUs from "../../pages/AboutUs/AboutUs";
import PrivacyAndTerms from "../../pages/Privacy&Terms/PrivacyAndTerms";
// import yarnsLogo from '../../Assets/icons/Y-b-m-g-web.svg'

const styles = {
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },
  content: {
    padding: "16px"
  }
};

const mql = window.matchMedia(`(min-width: 800px)`)

class SideNav extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      docked: false,
      open: false,
      search:false,
      unreadConversationCount:0
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.onSetOpen = this.onSetOpen.bind(this)
  }

  componentWillReceiveProps(nextProps, nextContext) {

  }

  componentDidMount() {
    let messageList = [];
    const self = this;
    let unreadConversationCount = 0;
    if (localStorage.getItem("uid")) {
      this.setState({recentMessages: []}, () => {
        console.log("Params", localStorage.getItem("uid"),);
        defaultDatabase.ref('users/').child(localStorage.getItem("uid")).child('messages').on('value', function (snapshot) {
          messageList = [];
          unreadConversationCount = 0;
          snapshot.forEach(function (childSnapshot) {
            console.log("claling on value");
            let recentMessage = childSnapshot.val();
            if ((recentMessage.sentBy !== localStorage.getItem('uid') && !recentMessage.isRead)) {
              unreadConversationCount = unreadConversationCount + 1
            }
            console.log("unreadConversationCount header>>>", localStorage.getItem('uid'));
            console.log("unreadConversationCount header>>", recentMessage);
            messageList.push(recentMessage);
          });
          self.setState({recentMessages: messageList,unreadConversationCount}, () => {
            console.log('unreadConversationCount header', unreadConversationCount);
            defaultDatabase.ref('users/').child(localStorage.getItem("uid")).child('messages').limitToLast(1).on('child_added', function (snapshot) {
              debugger;
              let recentMessageList = [...self.state.recentMessages];
              let recentMessage = snapshot.val();
              console.log("new recent message ", recentMessage, recentMessageList);
              console.log("recentMessageList ", recentMessageList, recentMessageList.findIndex((item => item.adId === recentMessage.adId)));
              if (recentMessageList.findIndex((item => item.adId === recentMessage.adId)) === -1) {
                recentMessageList.push(recentMessage);
                self.setState({recentMessages: recentMessageList}, function () {
                });
              } else {
                recentMessageList[recentMessageList.findIndex((item => item.adId === recentMessage.adId))] = recentMessage
              }
            })
          });
          console.log("message list", messageList)
        });
      });
    }
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged)
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged)
  }

  onSetOpen(open) {
    this.setState({ open })
  }

  mediaQueryChanged() {
    this.setState({
      docked: false,
      open: false
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  };

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });
    if (ev) {
      ev.preventDefault()
    }
  }

  onCreateClick = () => {
    if((defaultAuth?.currentUser && defaultAuth?.currentUser?.email && defaultAuth?.currentUser?.emailVerified )) {
      this.props.history.push('/post-ad')
    }
    else if(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && !defaultAuth?.currentUser?.emailVerified){
      alert('Please verify your email to add post')
    }
  else{
      alert('please login/register for free to post ads ')
    }
  };

  onSearchClick = () => {
    this.props.history.push('/app-search')
  };

  onLogOut = () => {
    const self = this;
    firebase.auth().signOut().then(function() {
      self.props.saveUserProfile({});
      toastFeedback("Logged out successfully")
    }).catch(function(error) {
      // toastFeedback(error)
      console.log(error);
      // An error happened.
    });
  };

  render() {
    const {unreadConversationCount} = this.state;
    const sidebar =<SidebarContent unreadConversationCount={unreadConversationCount} onLogOut={this.onLogOut} user={this.props.user} history={this.props.history} profile={this.props.user} handleClose={this.handleClose} />;
    const contentHeader = (
        <div className="d-flex flex-row align-items-center w-100" style={{height:this.state.search ? 100 : 56}}>
          {!this.state.docked && (<div className='h-menu'>
                <div>
                  <Badge   badgeContent={unreadConversationCount} color="secondary">
                  <div><a onClick={this.toggleOpen} href="#" style={styles.contentHeaderMenuLink}>
                    <Menu className="svgIcons"  alt="Menu Button" />
                  </a>
                  </div>
                </Badge>
          </div>
          </div>
          )}
          <img src={logo} className="logo cursor-pointer" onClick={() => {this.props.history.push('/')
            window.location.reload()
          }}/>
          <div className="NunitoSemiBold ml-3 cursor-pointer" onClick={() => {this.props.history.push('/')
            window.location.reload()
          }}> JUST PLAY TODAY </div>
          <div className="spacer" style={{flex:1}}/>
          {console.log("user oooooo",this.props.user,)}
          {(this.props.user && !this.props.user.uid) && <div className='pr-2 login-reg cursor-pointer' onClick={() => this.props.history.push("/app/authentication")}>Login/Register</div>}
          {(this.props.user && this.props.user.uid) && <div className="dropdown mr-2">
            <div className="dropdown-toggle dropdown-acc" type="button" id="dropdownMenuButton"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {this.props.user && this.props.user.uid ? `Hello  ${this.props.user.displayName}` : "hhh" }
            </div>
            <div className="dropdown-menu  cursor-pointer" aria-labelledby="dropdownMenuButton">
              <NavLink
                  className="drop-down-content NunitoRegular d-flex  flex-row align-items-center cursor-pointer"
                  to="/app/dashboard">
                <Home />
                <div className='pl-2'>Home</div>
              </NavLink>
              <NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/my-ads">
                <Manage />
                <div className='pl-2'>Manage my ads</div>
              </NavLink>
              <NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/account-settings">
                <PersonIcon />
                <div className='pl-2'>My Details</div>
              </NavLink>
              {(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && defaultAuth?.currentUser?.emailVerified ) && <NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/messages">
                <Message/>
                <div className='pl-2'>Messages</div>
              </NavLink>
              }
              <NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/favorites">
                <FavoriteIcon />
                <div className='pl-2'>Favorites</div>
              </NavLink>
              {this.props.user && !this.props.user.uid ?<NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/profile">
                <Create />
                <div className='pl-2'>Create account</div>
              </NavLink> : null}
              {this.props.user && !this.props.user.uid ?<NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/authentication">
                <Logout />
                <div className='pl-2'>Login</div>
              </NavLink>: null}
              {this.props.user && this.props.user.uid ?<div onClick={()=>this.onLogOut()}
                                                            className="drop-down-content NunitoRegular cursor-pointer">
                <Logout />
                <div className='pl-2'>Log out</div>
              </div>: null}
              <NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/help">
                <HelpIcon />
                <div className='pl-2'>Help & FAQ</div>
              </NavLink>
              <NavLink
                className="drop-down-content NunitoRegular cursor-pointer"
                to="/app/contact-us">
              <Phone />
              <div className='pl-2'>Contact Us</div>
            </NavLink>
              <NavLink
                  className="drop-down-content NunitoRegular cursor-pointer"
                  to="/app/about-us">
                <Info />
                <div className='pl-2'>About Us</div>
              </NavLink>
            </div>
          </div>}
          {(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && defaultAuth?.currentUser?.emailVerified ) &&<Badge className="svgIcons ml-2 mr-3 message-icon cursor-pointer"  badgeContent={unreadConversationCount} color="secondary">
            <MessageUnRead   onClick={()=>this.props.history.push('/app/messages')}alt="Menu Button" /></Badge>}
          <div onClick={this.onCreateClick} className='d-flex flex-row post-add-div cursor-pointer'>
            <img src={pin} className='v-icon'/>
            <div className='pl-1 pr-1'>POST</div>
          </div>
        </div>
    );

    const sidebarProps = {
      sidebar,
      docked: this.state.docked,
      open: this.state.open,
      styles:{
        sidebar: {
          zIndex: 3,
        }
      },
      onSetOpen: this.onSetOpen
    };

    return (
        <Sidebar {...sidebarProps}>
          <MaterialTitlePanel title={contentHeader} styles={{paddingBottom:56}}>
            <Switch>
              <Route path="/" component={Dashboard} exact />
              <Route path="/app" component={Dashboard} exact />
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/authentication" component={Auth} />
              <Route path="/app/messages" component={Messages} />
              <Route path="/app/my-ads" component={MyProducts} />
              <Route path="/app/account-settings" component={AccountSettings}  exact />
              <Route path="/app/favorites" component={Favorites} exact/>
              <Route path="/app/help" component={HelpAndContact} exact/>
              <Route path="/app/contact-us" component={ContactUs} exact/>
              <Route path="/app/about-us" component={AboutUs} exact/>
              <Route path="/app/Privacy&Terms" component={PrivacyAndTerms} exact/>
              <Route path="/app/:adId" component={PostDetail} exact/>
              {/*<Route path="/app/messages" component={Messages} />*/}
              {/*<Route path="/app/connections" component={Connections} />*/}


            </Switch>
          </MaterialTitlePanel>
        </Sidebar>
    )
  }
}

const mapStateToProps = (state) => {
  console.log("user",state.ads.user);
  return {
    user:state.ads.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserProfile : (user) => {
      dispatch(saveUserProfile({user:{}}))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)
