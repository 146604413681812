import React, {Component} from "react"
import "./Sidebar.scss"
import PropTypes from "prop-types"
import close from '@material-ui/icons/AccessAlarm'
import {NavLink} from "react-router-dom"
import Close from '@material-ui/icons/HighlightOff';
import Home from '@material-ui/icons/Home';
import Manage from '@material-ui/icons/FileCopy';
import Message from '@material-ui/icons/Message';
import Create from '@material-ui/icons/PersonAdd';
import Logout from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import * as firebase from "firebase";
import FavoriteIcon from '@material-ui/icons/Favorite';
import {saveUserProfile} from "../../actions";
import connect from "react-redux/es/connect/connect";
import PersonIcon from '@material-ui/icons/Person';
import Badge from "@material-ui/core/Badge";
import {defaultAuth} from "../../Config/firebaseConfig";
import Phone from '@material-ui/icons/Phone';
import Info from '@material-ui/icons/Info';

const styles = {
  sidebar: {
    width: 280,
    color:"black",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "#f1f1f1",
    zIndex: 3
  },
  sidebarLink: {
    display: "flex",
    padding: "16px 0 5px 0px",
    marginBottom: "11px",
    color: "#635b67",
    textDecoration: "none"
  },
  sidebarLinkActive: {
    display: "block",
    padding: "16px 0px",
    color: "white",
    backgroundColor: "red",
    textDecoration: "none"
  },
  content: {
    padding: "0 20px",
    backgroundColor: "#f1f1f1"
  }
};

class SidebarContent extends Component {
  render() {
    const style = this.props.style ? {...styles.sidebar, ...this.props.style} : styles.sidebar
    return (
        <div className='sideBar'>
          <div className="sideTop d-flex cursor-pointer">
            <Close
                onClick={this.props.handleClose}
                src={close}
                className='cursor-pointer'
                alt="Close Button"
            />
            {/*<img src={yLogo} alt="yarns logo" className="sideBarLogo ml-auto "/>*/}
          </div>
          {/*<div className="d-flex flex-column cursor-pointer align-items-center justify-content-center pt-2 pl-3" onClick={()=>{*/}
          {/*  props.handleClose()*/}
          {/*  props.history.push({pathname: '/app/account-settings',*/}
          {/*    state: {active_id: "showProfileSettings" }})}}>*/}
          {/*  <img*/}
          {/*    onError={addProfileDefaultSrc}*/}
          {/*    src={props.profile.profile_image_url ? props.profile.profile_image_url : user}*/}
          {/*    alt="Profile pic"*/}
          {/*    className="sideImg"*/}
          {/*    width={50}*/}
          {/*    height={50}/>*/}
          {/*  <p className="mt-3 sideName NunitoBold">{props.profile.display_name}</p>*/}
          {/*</div>*/}
          <div style={styles.content} className='d-flex flex-column w-100'>
            <NavLink
                className="sideContent NunitoRegular d-flex  flex-row align-items-center cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/dashboard">
              <Home/>
              <div className='pl-2'>Home</div>
            </NavLink>
            {(this.props.user && this.props.user.uid) && <NavLink
                className="sideContent NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/my-ads">
              <Manage/>
              <div className='pl-2'>Manage my ads</div>
            </NavLink>}
            {(this.props.user && this.props.user.uid) && <NavLink
                className="sideContent NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/account-settings">
              <PersonIcon/>
              <div className='pl-2'>My Details</div>
            </NavLink>}
            {console.log('confirm user',defaultAuth?.currentUser?.email)}
            {(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && defaultAuth?.currentUser?.emailVerified) && <NavLink
                className="sideContent NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/messages">
              <Badge badgeContent={this.props.unreadConversationCount} color="secondary">
                <Message/>
              </Badge>
              <div className='pl-2'>Messages</div>
            </NavLink>}
            {(this.props.user && this.props.user.uid) && <NavLink
                className="sideContent NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/favorites">
              <FavoriteIcon/>
              <div className='pl-2'>Favorites</div>
            </NavLink>}
            {(this.props.user && !this.props.user.uid) && <NavLink
                className="sideContent NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/authentication">
              <Create/>
              <div className='pl-2'>Create account</div>
            </NavLink>}
            {this.props.user && !this.props.user.uid ? <NavLink
                className="sideContentA NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/authentication">
              <Logout/>
              <div className='pl-2'> Log In</div>
            </NavLink> : null}
            {this.props.user && this.props.user.uid ? <div
                className="sideContentA NunitoRegular cursor-pointer"
                onClick={this.props.onLogOut}
                style={styles.sidebarLink}
                to="/app/authentication">
              <Logout/>
              <div className='pl-2'> Log out</div>
            </div> : null}
            <NavLink
                className="sideContentH NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/help">
              <HelpIcon/>
              <div className='pl-2'>Help & FAQ</div>
            </NavLink>
            <NavLink
                className="sideContentH NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/contact-us">
              <Phone/>
              <div className='pl-2'>Contact Us</div>
            </NavLink>
            <NavLink
                className="sideContentH NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/about-us">
              <Info/>
              <div className='pl-2'>About Us</div>
            </NavLink>
            <NavLink
                className="sideContentH NunitoRegular cursor-pointer"
                onClick={this.props.handleClose}
                style={styles.sidebarLink}
                to="/app/Privacy&Terms">
              <Info/>
              <div className='pl-2'>Privacy and Terms</div>
            </NavLink>
            {/*<div className='logout-button cursor-pointer' onClick={() => keyCloakInstance.logout()}>*/}
            {/*  Log out*/}
            {/*</div>*/}
            <hr className="sideH"/>
          </div>
          <div style={{backgroundColor:"#f1f1f1"}}>
          <hr className="sideH" />
          {/*<div className="d-flex align-items-center justify-content-center">*/}
          {/*  <a className="sideFooter NunitoRegular cursor-pointer" target="_blank" href="https://yarns.life/Privacy" >Privacy Policy</a><p*/}
          {/*  className="sideFooter NunitoRegular pl-2 pr-2">|</p> <a target="_blank" href="https://yarns.life/Terms" className="sideFooter NunitoRegular cursor-pointer">Terms &*/}
          {/*  Conditions</a>*/}
          {/*</div>*/}
          <div className="d-flex align-items-center justify-content-center">
            <p className="sideFooter NunitoRegular">© Copyright {new Date().getFullYear()}. JustPlayToday LLC.</p>
          </div>
          </div>
        </div>
    )
  };
}

SidebarContent.propTypes = {
  style: PropTypes.object
};

const mapStateToProps = (state) => {
  console.log("user",state.ads.user);
  return {
    user:state.ads.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserProfile : (user) => {
      dispatch(saveUserProfile({user:{}}))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent)






