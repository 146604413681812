import React, {Component} from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import "./CreateAd.scss";
import Header from "./Header/Header";
import connect from "react-redux/es/connect/connect";
import {createAd, getCategories} from "../../actions";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import UploadMedia from "./UploadMedia/UploadMedia";
import {defaultAuth, defaultDatabase, defaultFirestore} from "../../Config/firebaseConfig";
import {BOX_OPENED_UNUSED, BRAND_NEW, LIGHTLY_USED, USED,} from "../../actions/constants";
import * as firebase from "firebase";
import {toastFeedback} from "../../Toast/ToastFunctions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import AutoComplete from "../../components/SearchBar/AutoComplete";
import Footer from "../../components/Footer/Footer";

let statesAndCities = new Map();
statesAndCities.set("Karnataka", [
  "Bangalore",
  "Chennai",
  "Mysore",
  "Mangalore",
  "Hyderabad",
  "Vishakapatnam",
]);
statesAndCities.set("Goa", [
  "Bangalore",
  "Chennai",
  "Mysore",
  "Mangalore",
  "Hyderabad",
  "Vishakapatnam",
]);
statesAndCities.set("Kerala", [
  "Bangalore",
  "Chennai",
  "Mysore",
  "Mangalore",
  "Hyderabad",
  "Vishakapatnam",
]);
statesAndCities.set("Tamilnadu", [
  "Bangalore",
  "Chennai",
  "Mysore",
  "Mangalore",
  "Hyderabad",
  "Vishakapatnam",
]);
statesAndCities.set("Telangana", [
  "Bangalore",
  "Chennai",
  "Mysore",
  "Mangalore",
  "Hyderabad",
  "Vishakapatnam",
]);

const popularCities = ['All',"Bangalore","Chennai","Mysore","Mangalore","Hyderabad","Vishakapatnam"];


class CreateAd extends Component {
  constructor(props, context) {
    super(props, context);
    let date = new Date();
    this.state = {
      category: "",
      subCategory: "",
      title: "",
      price: "",
      description: "",
      condition: "",
      ad_id: "",
      mfd: new Date(),
      state: "",
      city: "",
      updated_at: date.getTime(),
      created_at: date.getTime(),
      owner: "",
      media: [],
      mediaUploading: false,
      states: [],
      categories: [],
      main_categories: [],
      conditionArray: [BRAND_NEW, BOX_OPENED_UNUSED, LIGHTLY_USED, USED],
      locations:["All","Bangalore","Chennai","Mysore","Mangalore","Hyderabad","Mumbai","Ahmedabad"]
    };
  }

  componentDidMount() {
    let states = [];
    statesAndCities.forEach(function (value, key) {
      states.push(key);
    });
    let cities = [];
    defaultDatabase.ref('IndianCities/').once('value',   function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        const city = childSnapshot.val();
        if(!popularCities.includes(city)){
          cities.push(city);
        }
      });
      const citiesData = [...popularCities,...cities];
      self.setState({locations:citiesData})
    });

    this.setState({ states: states }, function () {});
    let adsRef = defaultFirestore.collection("ads").doc();
    if (this.props.categories.length === 0) {
      this.props.getCategories();
    } else {
      this.setState(
          {
            categories: this.props.categories,
            main_categories: Object.keys(this.props.categories),
          },
          function () {
            // console.log(this.state.main_categories,this.state.categories)
          }
      );
    }

    const self = this;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user === null) {
        self.props.history.push("/app/authentication");
      } else {
        self.setState({ ad_id: adsRef.id, owner: user.uid });
      }
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(
        {
          categories: nextProps.categories,
          main_categories: Object.keys(nextProps.categories),
        },
        function () {
          // console.log(this.state.main_categories,this.state.categories)
        }
    );
  }

  onCreateSuccess = () => {
    // toastFeedback("Post created successfully!");
    let adRef = defaultFirestore.collection("ads").doc();
    this.setState({ ad_id: adRef.id }, function () {
      toastFeedback(`add id +${adRef.id}`);
    });
    this.props.history.push("/app");
  };

  onCategoryChange = (event) => {
    this.setState({ category: event.target.value, subCategory: "" });
  };

  handleSubCtyChange = (event) => {
    this.setState({ subCategory: event.target.value });
  };

  handleCityChange = (city) => {
    this.setState({ city: city });
  };

  handleMfdChange = (date) => {
    this.setState({ mfd: date });
  };

  onConditionChange = (event) => {
    this.setState({ condition: event.target.value });
  };

  getAllTitleKeys = (title) => {
    let mainKeys = title.split(" " || ",");
    let prevItem = "";
    let subKeys = [];
    mainKeys.forEach((item, index) => {
      if (index !== 0) subKeys.push(prevItem.concat(" ").concat(item));
      else prevItem = item;
    });
    let prevKey = "";
    let subKeysArray = [];
    for (let i = 0; i < title.length; i++) {
      prevKey = prevKey.concat(title.charAt(i));
      if (i > 2) subKeysArray.push(prevKey);
    }
    return [...mainKeys, ...subKeys, ...subKeysArray];
  };

  onSaveAd = () => {
    debugger
    let state = this.state;

    let mediaCopy = state.media.map((item) => {
      return item.url;
    });

    if (
        state.title &&
        state.category &&
        state.price &&
        state.subCategory &&
        state.condition &&
        state.city
    ) {
      let keySet = new Set([
        state.title.toLowerCase(),
        state.category,
        state.subCategory,
        state.city,
      ]);
      debugger
      this.props.createAd(
          {
            title: state.title,
            description: state.description,
            price: state.price,
            category: state.category,
            subCategory: state.subCategory,
            condition: state.condition,
            id: state.ad_id,
            mfd: '',
            state: state.state,
            city: state.city,
            isProductAvailable: true,
            owner: state.owner,
            ownerName: this.props.user.displayName || '',
            created_at: state.created_at,
            updated_at: state.updated_at,
            media: mediaCopy,
            keyArray: firebase.firestore.FieldValue.arrayUnion(...keySet),
          },
          this.state.ad_id,
          this.onCreateSuccess
      );
    } else {
      toastFeedback("Please enter all required fields");
    }
  };

  onTitleChange = (event) => {
    this.setState({ title: event.target.value }, function () {
      this.getAllTitleKeys(this.state.title);
    });
  };

  onPriceChange = (event) => {
    this.setState({ price: event.target.value });
  };

  onStateChange = (event) => {
    this.setState({ state: event.target.value });
  };

  onUploadMedia = (mediaFiles) => {
    this.setState({ media: mediaFiles });
  };

  onMediaUploading = (isUploading) => {
    this.setState({ mediaUploading: isUploading });
  };

  render() {
    const { locations } = this.state;
    return (
        <div >
          {(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && !defaultAuth?.currentUser?.emailVerified) && <p style={{background:'gray',color:'white'}} className='mb-0 p-1'>Please verify your email to add product</p>}
        <div className="d-flex align-items-center flex-column ml-1 mr-1 justify-content-center createAdWrapper" style={{width:'calc(100% -10px)'}}>
          <Header history={this.props.history} />
            <div className="d-flex ad-input-group pt-3 justify-content-start align-items-start">
              <p className="d-flex align-items-start  justify-content-start headings mt-4">
                Post your ad
              </p>
            </div>
            <div className="text-left create-ad-form d-flex align-items-center flex-column pl-2 price-2 pt-2 createAdWrapper">
              <div className="ad-input-group">
                <div className="p-2">
                  <TextField
                      value={this.state.title}
                      onChange={this.onTitleChange}
                      id="standard-basic"
                      label="Product title *"
                      className="ad-input"
                  />
                </div>
                <div className="p-2">
                  <TextField
                      id="standard-basic"
                      value={this.state.price}
                      onChange={this.onPriceChange}
                      label="Product price *"
                      type="number"
                      min="-1"
                      className="ad-input"
                  />
                </div>
              </div>
              <div className="ad-input-group">
                <div className="p-2">
                  <FormControl className="ad-input">
                    <InputLabel id="demo-simple-select-helper-label">
                      Category *
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label text-left"
                        id="demo-simple-select-helper"
                        value={this.state.category}
                        onChange={this.onCategoryChange}
                    >
                      {this.state.main_categories.map((mainCategory) => {
                        return (
                            <MenuItem value={mainCategory}>{mainCategory}</MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>Please select category</FormHelperText>
                  </FormControl>
                </div>
                <div className="p-2">
                  <FormControl disabled={!this.state.category} className="ad-input">
                    <InputLabel id="demo-simple-select-helper-label">
                      Sub category *
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label text-left"
                        id="demo-simple-select-helper"
                        value={this.state.subCategory}
                        onChange={this.handleSubCtyChange}
                    >
                      {this.state.category &&
                      this.state.categories[this.state.category].map(
                          (mainCategory) => {
                            return (
                                <MenuItem value={mainCategory}>
                                  {mainCategory}
                                </MenuItem>
                            );
                          }
                      )}
                    </Select>
                    <FormHelperText>Please select sub category</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="ad-input-group">
                <div className="pl-2 price-2">
                  {" "}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Manufactured on"
                        format="MM/dd/yyyy"
                        className="ad-input"
                        value={this.state.mfd}
                        onChange={this.handleMfdChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="pl-2 price-2">
                  {" "}
                  <FormControl className="ad-input mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Condition *
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label text-left"
                        id="demo-simple-select-helper"
                        value={this.state.condition}
                        onChange={this.onConditionChange}
                    >
                      {this.state.conditionArray &&
                      this.state.conditionArray.map((condition) => {
                        return <MenuItem value={condition}>{condition}</MenuItem>;
                      })}
                    </Select>
                    <FormHelperText>Please select condition</FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <div className="p-2 d-flex align-items-center justify-content-center">
                  <FormControl className="ad-text-area">
                    {/*<InputLabel id="demo-simple-select-helper-label">Product description</InputLabel>*/}
                    <label className="input-label">Enter product description</label>
                    <TextareaAutosize
                        rowsMax={7}
                        aria-label="minimum height"
                        placeholder="Enter product description"
                        onChange={(event) =>
                            this.setState({ description: event.target.value })
                        }
                        value={this.state.description}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="ad-input-group">
                <div className="p-2">
                  <FormControl  className="ad-input">
                    {/*<InputLabel id="demo-simple-select-helper-label">*/}
                    {/*  City **/}
                    {/*</InputLabel>*/}

                    <div className='location-dropdown-container'>
                      <AutoComplete handleLocation={this.handleCityChange} cities={locations}/>
                    </div>
                    {/*<Select*/}
                    {/*  labelId="demo-simple-select-helper-label text-left"*/}
                    {/*  id="demo-simple-select-helper"*/}
                    {/*  value={this.state.city}*/}
                    {/*  onChange={this.handleCityChange}*/}
                    {/*>*/}

                    {/*    {locations.map((mainCategory) => {*/}
                    {/*        return (*/}
                    {/*          <MenuItem value={mainCategory}>*/}
                    {/*            {mainCategory}*/}
                    {/*          </MenuItem>*/}
                    {/*        );*/}
                    {/*      })}*/}
                    {/*</Select>*/}
                    {/*<FormHelperText>Please select city</FormHelperText>*/}
                  </FormControl>
                </div>
              </div>
              <UploadMedia
                  onMediaUploading={this.onMediaUploading}
                  onUploadMedia={this.onUploadMedia}
                  ad_id={this.state.ad_id}
              />
              <Button
                  onClick={this.onSaveAd}
                  variant="contained"
                  disabled={(defaultAuth?.currentUser && defaultAuth?.currentUser?.email && !defaultAuth?.currentUser?.emailVerified)}
                  className="mt-5 mb-4"
                  color="primary"
              >
                Save
              </Button>
            </div>
          </div>
          <Footer/>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.ads.categories,
    user: state.ads.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAd: (ad, ad_id, onCreateSuccess) => {
      dispatch(createAd({ ad: ad, ad_id: ad_id, onCreateSuccess }));
    },
    getCategories: () => {
      dispatch(getCategories({}));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAd);
