import React, { Component } from "react";
import "./Chat.scss";
import { defaultDatabase } from "../../../Config/firebaseConfig";
import TextareaAutosize from "react-textarea-autosize";
import sendIcon from "../../../Assets/icons/arrow-alt-square-up.svg";
import sendActiveIcon from "../../../Assets/icons/send-active.svg";
import ChatRightCard from "../../ChatCards/ChatRightCard";
import ChatLeftCard from "../../ChatCards/ChatLeftCard";
import leftArrow from "../../../Assets/icons/arrow-left-black.svg";
import { addProfileDefaultSrc } from "../../../utils/globalFunctions";
import { toastFeedback } from "../../../Toast/ToastFunctions";

class Chats extends Component {
    state = {
        messages: [],
        message: "",
        text_message: null,
        isMessagesLoading: false,
        current_recent_message: null,
        isLoadingForFirstTime:true,
        bottomOfMessagesRef: React.createRef(),
    };

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "auto" });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollToBottom();
    }

    componentDidMount() {
        this.scrollToBottom();
        if (this.props) {
            console.log("Message id",this.props.current_message_id);
            this.setState({messages: [], current_recent_message: this.props.current_recent_message,},function () {
                const messageList = [];
                const self = this;
                defaultDatabase
                    .ref("chats/")
                    .child(this.props.current_message_id)
                    .child("messages")
                    .once("value", function (snapshot) {
                        snapshot.forEach(function (childSnapshot) {
                            messageList.push(childSnapshot.val());
                        });
                        self.setState({messages: self.state.messages.concat([...messageList])}, () => {
                            defaultDatabase
                                .ref("chats/")
                                .child(self.props.current_message_id)
                                .child("messages").limitToLast(2)
                                .on("child_added", function (snapshot) {
                                    let list = [...self.state.messages];
                                    let message = snapshot.val();
                                    if(list.findIndex((item => item.id === message.id )) === -1){
                                        list.push(message);
                                        self.setState({ messages: list },function () {
                                        });
                                    }
                                });
                        },()=> console.log("messages",self.state.messages));
                    });
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps) {
            console.log("Message id",nextProps.current_message_id);
            this.setState({messages: [], current_recent_message: nextProps.current_recent_message,},function () {
                const messageList = [];
                const self = this;
                debugger
                defaultDatabase
                    .ref("chats/")
                    .child(nextProps.current_message_id)
                    .child("messages")
                    .once("value", function (snapshot) {
                        console.log('size',snapshot.size);
                        snapshot.forEach(function (childSnapshot) {
                            let message = childSnapshot.val();
                            if(messageList.findIndex((item => item.id === message.id )) === -1){
                                messageList.push(message);
                                self.setState({ messages: Array.from(new Set(messageList)) },function () {
                                });
                            }
                        });
                        self.setState({messages:messageList}, () => {
                            defaultDatabase
                                .ref("chats/")
                                .child(nextProps.current_message_id)
                                .child("messages").limitToLast(2)
                                .on("child_added", function (snapshot) {
                                    let list = [...self.state.messages];
                                    let message = snapshot.val();
                                    if(list.findIndex((item => item.id === message.id )) === -1){
                                        list.push(message);
                                        self.setState({ messages: Array.from(new Set(list))},function () {
                                        });
                                    }
                                });
                        });
                    });
            });
        }
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.onSendClick();
        }
    };

    onSendClick = () => {
        console.log("user name",this.props.user);
        debugger
        const { current_recent_message } = this.state;
        if (this.state.text_message) {
            const textMessage = this.state.text_message;
            this.setState({text_message:""});
            const self = this;
            let d = new Date();
            let messageTime = d.getTime();
            let messageId =
                current_recent_message.adId +
                current_recent_message.owner +
                current_recent_message.subscriber;
            let receiver =
                localStorage.getItem("uid") === current_recent_message.owner
                    ? current_recent_message.subscriber
                    : current_recent_message.owner;
            let messageRef = defaultDatabase
                .ref("chats")
                .child(messageId)
                .child("messages")
                .push();

            messageRef
                .set({
                    id: messageRef.key,
                    message: textMessage,
                    receiver: receiver,
                    sentBy: localStorage.getItem("uid"),
                    created_at: messageTime,
                })
                .then(function () {
                    toastFeedback("Sent message successfully");
                    console.log("unreadConversationCount sent by",localStorage.getItem('uid'));
                    defaultDatabase
                        .ref("users/")
                        .child(current_recent_message.subscriber)
                        .child("messages")
                        .child(messageId)
                        .set({
                            adId: current_recent_message.adId,
                            adName: current_recent_message.adName,
                            adImage: current_recent_message.adImage || '',
                            owner: current_recent_message.owner,
                            senderName: self.props.user.displayName,
                            sentBy: localStorage.getItem("uid"),
                            receiver: receiver,
                            created_at: messageTime,
                            subscriber: current_recent_message.subscriber,
                            message: textMessage,
                            messageId: messageId,
                            isRead:false,
                        });
                    defaultDatabase
                        .ref("users/")
                        .child(current_recent_message.owner)
                        .child("messages")
                        .child(messageId)
                        .set({
                            adId: current_recent_message.adId,
                            adName: current_recent_message.adName,
                            owner: current_recent_message.owner,
                            adImage: current_recent_message.adImage || '',
                            sentBy: localStorage.getItem("uid"),
                            receiver: receiver,
                            senderName: self.props.user.displayName,
                            created_at: messageTime,
                            subscriber: current_recent_message.subscriber,
                            message:textMessage,
                            messageId: messageId,
                            isRead:false,
                        }).then();

                })
                .catch(function (error) {
                    console.error("Error writing document: ", error);
                    return false;
                }).finally(()=>{self.setState({text_message:null})
            });
        }
    };

    onTextMessageChange = (event) => {
        this.setState({ text_message: event.target.value });
    };

    getChatCard = (message, index, current_user) => {
        if (message.sentBy === localStorage.getItem("uid")) {
            return <ChatLeftCard key={index} message={message} />;
        } else {
            return <ChatRightCard key={index} message={message} />;
        }
    };

    render() {
        let {current_recent_message} =  this.props;
        return (
            <div className="message-main d-flex flex-column align-items-center justify-content-center">
                <div className="message-header d-flex align-self-center justify-content-center">
                    <div
                        className="d-flex align-items-center justify-content-center back-button-container"
                        onClick={() => this.props.onBackButtonClick()}>
                        <img alt={"back-button"} onError={addProfileDefaultSrc} src={leftArrow} className="back-button" />
                    </div>
                    <img
                        alt="profile_image"
                        src={current_recent_message.adImage ? current_recent_message.adImage : "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"}
                        className="message-header-image user-avatar"
                        onError={addProfileDefaultSrc}
                    />
                    <div className="d-flex flex-column align-items-center justify-content-center ml-2">
                        <p className="message-header-title w-100 mb-0">
                            {current_recent_message.adName}
                        </p>
                    </div>
                    <div className="spacer" />
                </div>
                <div className="message-content-layout">
                    {this.state.isMessagesLoading ? (
                        <p className="text-center">Loading....</p>
                    ) : null}
                    {this.state.messages && this.state.messages.length > 0
                        ? this.state.messages.map((message, index) => {
                            {
                                return this.getChatCard(
                                    message,
                                    index,
                                    this.props.current_user
                                );
                            }
                        })
                        : null}
                    <div
                        style={{ float: "left", clear: "both" }}
                        ref={(el) => {
                            this.messagesEnd = el;
                        }}
                    ></div>
                </div>
                <div className="form-inline message-bottom-layout">
                    <TextareaAutosize
                        className="message-input"
                        minRows={1}
                        maxRows={3}
                        placeholder="Type your message here…"
                        value={this.state.text_message}
                        onKeyDown={this.onEnterPress}
                        onChange={this.onTextMessageChange}
                    />
                    <img
                        src={!this.state.text_message ? sendIcon : sendActiveIcon}
                        className="message-send-button"
                        onClick={this.onSendClick}
                        alt="Send icon"
                    />
                </div>
            </div>
        );
    }
}

export default Chats;
