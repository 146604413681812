import React, {Component} from 'react';
import {defaultDatabase} from "../../Config/firebaseConfig";
import RecentMessageList from "./RecentMessageList/RecentMessageList";
import './Messages.css'
import Chats from "./Chats/Chats";
import {getAd} from "../../actions";
import connect from "react-redux/es/connect/connect";

class Messages extends Component {

    state = {
        recentMessages: [],
        message: '',
        isInMessages: false,
        current_recent_message:null,
        current_message_id: 0
    };

    componentDidMount() {
        let messageList = [];
        const self = this;
        let unreadConversationCount = 0;
        if(localStorage.getItem("uid")) {
            this.setState({recentMessages:[]},()=>{
                console.log("Params",localStorage.getItem("uid"),);
                defaultDatabase.ref('users/').child(localStorage.getItem("uid")).child('messages').orderByChild('created-at').on('value', function (snapshot) {
                    messageList = [];
                    unreadConversationCount = 0;
                    snapshot.forEach(function (childSnapshot) {
                        console.log("claling on value");
                        let recentMessage = childSnapshot.val();
                        if((recentMessage.sentBy !== localStorage.getItem('uid') && !recentMessage.isRead)){
                            unreadConversationCount= unreadConversationCount + 1
                        }
                        console.log("unreadConversationCount >>>",localStorage.getItem('uid'));
                        console.log("unreadConversationCount >>",recentMessage);
                        messageList.push(recentMessage);
                    });
                    self.setState({recentMessages: messageList},()=>{
                        console.log('unreadConversationCount',unreadConversationCount);
                        defaultDatabase.ref('users/').child(localStorage.getItem("uid")).child('messages').limitToLast(1).on('child_added', function (snapshot) {
                            debugger;
                            let recentMessageList = [...self.state.recentMessages];
                            let recentMessage = snapshot.val();
                            console.log("new recent message ",recentMessage,recentMessageList);
                            console.log("recentMessageList ",recentMessageList,recentMessageList.findIndex((item => item.adId === recentMessage.adId )));
                            if(recentMessageList.findIndex((item => item.adId === recentMessage.adId )) === -1){
                                recentMessageList.push(recentMessage);
                                self.setState({ recentMessages: recentMessageList },function () {
                                });
                            }
                            else{
                                recentMessageList[recentMessageList.findIndex((item => item.adId === recentMessage.adId ))] = recentMessage
                            }
                        })
                    });
                    console.log("message list", messageList)
                });
            });
            window.addEventListener("resize", this.updateScreenWidth);
            this.updateScreenWidth()
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // if (nextProps) {
        //     this.setState({recentMessages: [], current_recent_message: nextProps.current_recent_message,},function () {
        //         const messageList = [];
        //         const self = this;
        //         console.log('current_message_id',nextProps.current_message_id);
        //         if(nextProps.current_message_id){
        //             defaultDatabase
        //                 .ref("chats/")
        //                 .child(nextProps.current_message_id)
        //                 .child("messages")
        //                 .once("value", function (snapshot) {
        //                     snapshot.forEach(function (childSnapshot) {
        //                         messageList.push(childSnapshot.val());
        //                     });
        //                     self.setState({recentMessages: self.state.recentMessages.concat([...messageList])}, () => {
        //                         defaultDatabase
        //                             .ref("chats/")
        //                             .child(nextProps.current_message_id)
        //                             .child("messages")
        //                             .on("child_added", function (snapshot) {
        //                                 let list = [...self.state.recentMessages];
        //                                 let message = snapshot.val();
        //                                 if(list.findIndex((item => item.id === message.id )) === -1){
        //                                     list.push(message);
        //                                     self.setState({ recentMessages: list },function () {
        //                                     });
        //                                 }
        //                             });
        //                     });
        //                 });
        //         }});
        // }
    }

    sendMessage = () => {

    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateScreenWidth)
        this.updateScreenWidth()
    }

    updateScreenWidth = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isSmallScreen: true
            })
        } else {
            this.setState({
                isSmallScreen: false
            })
        }
    };

    onRecentMessageClick = (message) => {
        console.log("message", message.messageId);

        defaultDatabase
            .ref("users/")
            .child(localStorage.getItem('uid'))
            .child("messages")
            .child(message.messageId)
            .child('isRead').set(true);

        this.setState({current_message_id: message.messageId,current_recent_message:message,isInMessages:true})
    };

    onBackButtonClick = () => {
        this.setState({isInMessages: false})
    };

    getFullMessageView = () => {
        const current_message_id = this.state.current_message_id;
        if (!this.state.isSmallScreen) {
            return (
                <div className='message-container d-flex justify-content-center flex-row align-items-center'>
                    <div className='recentMessageList'>
                        <RecentMessageList current_message_id={current_message_id}
                                           recentChats={this.state.recentMessages}
                                           onRecentMessageClick={this.onRecentMessageClick}/>
                    </div>
                    <div className='chatList'>
                        {current_message_id ? <Chats messages={this.state.recentMessages}
                                                     history={this.props.history}
                                                     user={this.props.user}
                                                     onBackButtonClick={this.onBackButtonClick}
                                                     current_recent_message={this.state.current_recent_message}
                                                     current_message_id={current_message_id}/> : null
                        }
                    </div>
                </div>
            )
        } else {
            if (!this.state.isInMessages) {
                return (
                    <div className='message-container d-flex justify-content-center flex-row align-items-center'>
                        <div className='recentMessageList'>
                            <RecentMessageList current_message_user_id={current_message_id}
                                               recentChats={this.state.recentMessages}
                                               onRecentMessageClick={this.onRecentMessageClick}/>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className='message-container d-flex justify-content-center flex-row align-items-center'>
                        <div className='chatList'>{current_message_id && <Chats messages={this.state.recentMessages} user={this.props.user} current_recent_message={this.state.current_recent_message} history={this.props.history} onBackButtonClick={this.onBackButtonClick}
                                                                                current_message_id={current_message_id}/>}
                        </div>
                    </div>
                )
            }
        }
    };

    render() {
        let {recentMessages} = this.state;
        return (
            <div style={{paddingTop: "30px"}}>
                {recentMessages?.length >0 ?this.getFullMessageView() : <div className='d-flex align-items-center h-100 w-100 justify-content-center'>No messages found</div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ad: state.ads.ad,
        user:state.ads.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAd: (adId) => {
            dispatch(getAd({ adId }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

