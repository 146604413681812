/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
            borderColor: 'red'
        },

    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "yellow !important"
    },
    cssLabel: {
        fontWeight:'bold'
    },
    cssOutlinedInput: {
        borderColor: 'red'
    }


});

const onTagsChange = (event, values) => {
    this.props.handleLocation(values);
    console.log("values",values);
};



export default function AutoComplete({cities,handleLocation}) {
    const classes = useStyles();

    return (
        <Autocomplete
            id="country-select-demo"
            style={{ width: 300,borderColor:'red' }}
            defaultValue={cities[0]}
            options={cities}
            classes={{
                option: classes.option,
            }}

            onChange={(event,values)=>handleLocation(values)}
            autoHighlight
            getOptionLabel={(option) => option}
            renderOption={(option) => (
                <React.Fragment>
                    {option}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="CHOOSE A CITY"
                    variant="outlined"
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                        },
                    }}
                    inputProps={{
                        root: classes.cssOutlinedInput,
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
}
