export const CREATE_AD = "CREATE_AD";
export const DELETE_AD = "DELETE_AD";
export const UPDATE_Ad = "UPDATE_AD";

export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";

export const LOGIN_REGISTER_CLICK = "LOGIN_REGISTER_CLICK";

export const UPLOAD_AD_MEDIA = "UPLOAD_AD_MEDIA";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const GET_KEYS = "GET_KEYS";
export const GET_KEYS_SUCCESS = "GET_KEYS_SUCCESS";

export const GET_ADS = "GET_ADS";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";

export const GET_FRESH_RECOMMENDATIONS = "GET_FRESH_RECOMMENDATIONS";

export const SEARCH_ADS = "SEARCH_ADS";
export const SEARCH_ADS_SUCCESS = "SEARCH_ADS_SUCCESS";

export const CURRENT_SEARCH_TITLE_KEYS = "CURRENT_SEARCH_TITLE_KEYS";

export const GET_AD = "GET_AD";
export const GET_AD_SUCCESS = "GET_AD_SUCCESS";

export const BRAND_NEW = "Brand New";
export const BOX_OPENED_UNUSED = "Box opened - unused";
export const LIGHTLY_USED = "Lightly used";
export const USED = "Used";

export const ADD_TODO = "ADD_TODO";
export const DELETE_TODO = "DELETE_TODO";

export const UPDATE_CITIES = "UPDATE_CITIES";

export const CITIES = ["Bangalore",
    "Chennai",
    "Mysore",
    "Mangalore",
    "Hyderabad",
    "Vishakapatnam"];

export const CATEGORIES = [
    "Accessories",
    "Drums and percussion",
    "Guitar",
    "Indian instruments",
    "Piano/keys",
    "Studio and recording",
    "Violin",
    "Wind instruments"];

export const SUBCATEGORIES = [
    "Guitar accessories",
    "Drums and percussions accessories",
    "Keyboard accessories",
    "Acoustic drums",
    "Snare drums",
    "Electric drums",
    "Cajons","Djembes",
    "Other percussions",
    "Acoustic guitars",
    "Electric guitars",
    "Classical guitars",
    "Bass guitars",
    "Clarinets",
    "Flutes",
    "Harmonica",
    "Recorders",
    "Saxophones",
    "Trombones",
    "Trumpets",
    "Cello",
    "Viola",
    "Violin",
    "Audio interface",
    "Monitors and speakers",
    "Headphones",
    "MIDI keyboards",
    "Mixers",
    "Pre-amps",
    "Guitars amplifiers",
    "Bass amplifiers",
    "Keyboard amplifiers",
    "Pianos",
    "keys",
    "Tabla",
    "Mridangam",
    "Dholak",
    "Kanjira",
    "Ghatam",
    "Harmonium",
    "Tanpura",
    "Veena",
    "Sitar",
    "Shruthi box"
];

