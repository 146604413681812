import React from 'react';
import './ChatRightCard.scss'
import { convertMillisToLocalTime, convertUTCToLocalTime } from "../../utils/globalFunctions"

const ChatRightCard = ({message}) => {
  return (
    <div  className="chat-right-card-container d-flex align-self-end justify-content-end">
      <div className="spacer"></div>
      <div className="chat-right-card-content d-flex">
          <div>
            <div className="chat-right-card-message-container">
              <p className='chat-right-card-message'>{message.message}</p>
            </div>
            <p className="chat-right-card-time-label"> {convertMillisToLocalTime(message.created_at)}</p>
          </div>
      </div>
    </div>
  );
};

export default ChatRightCard;
