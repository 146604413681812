import React, {Component} from 'react';
import './SearchBar.scss'
import SearchIcon from '@material-ui/icons/Search';
import {createAd, getAds, getCategories, getSearchKeys} from "../../actions";
import connect from "react-redux/es/connect/connect";
import { sizing } from '@material-ui/system';
import {defaultDatabase} from "../../Config/firebaseConfig";
import {CATEGORIES, CITIES, SUBCATEGORIES} from "../../actions/constants";
import Dropdown from 'react-dropdown';
import AutoComplete from "./AutoComplete";
const popularCities = ['All',"Bangalore","Chennai","Mysore","Mangalore","Hyderabad","Vishakapatnam"];

const styles = {
    input1: {
        height: 50
    },
    input2: {
        height: 200,
        fontSize: "3em"
    }
};

class SearchBar extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open:false,
            options:[],
            loading:false,
            searchText:"",
            city:"All",
            searchKeys:[],
            category:[],
            categories:CATEGORIES,
            locations:["All","Bangalore","Chennai","Mysore","Mangalore","Hyderabad","Mumbai","Ahmedabad"]
        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.onSearch(this.state.searchText)
        }
    };

    componentDidMount() {
        const self = this;
        let keys = [];
        defaultDatabase.ref('searchKeys/').once('value',   function(snapshot) {
            snapshot.forEach(function(childSnapshot) {
                keys.push(childSnapshot.val());
            });
            self.setState({searchKeys:keys},function () {
                self.props.getSearchKeys(keys);
            })
        });
        let cities = [];
        defaultDatabase.ref('IndianCities/').once('value',   function(snapshot) {
            snapshot.forEach(function(childSnapshot) {
                    const city = childSnapshot.val();
                    if(!popularCities.includes(city)){
                        cities.push(city);
                    }
            });
            const citiesData = [...popularCities,...cities];
            self.setState({locations:citiesData})
        });
    }

    handleLocation = (city) => {
        console.log('city>>>',city);
        this.setState({city:city}, () => {
            this.props.onLocationSelect(city,this.state.searchText);
        })
    };

    onSearchProducts = (event) => {
        const self = this;
        this.setState({searchText:event.target.value},function () {
            if(!this.state.searchText){
                this.props.onSearch("")
            }
        })
    };

    onSearchClick = () => {
        this.props.onSearch(this.state.searchText)
    };

    handleCategory = () =>{

    };

    render() {
        return (
            <>
                <div className='search-container-large flex-row  align-items-center justify-content-center'>
                    <div className='location-dropdown-container'>
                        <AutoComplete  handleLocation={this.handleLocation} cities={this?.props?.cities}/>
                    </div>
                    <div className='searchBar d-flex align-items-center justify-content-between flex-row'>
                        <input onKeyUp={this.onSearchProducts}onKeyDown={this._handleKeyDown} className='search-input' placeholder="Search for products"/>
                        {this.state.loading &&<div style={{height:20,width:20}} className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>}
                        <SearchIcon style={{cursor:'pointer'}} className='m-2 ' onClick={this.onSearchClick}/>
                    </div>
                </div>
                <div className='search-container flex-column align-items-center justify-content-center'>
                    <div className='searchBar ml-2 d-flex align-items-center justify-content-between flex-row'>
                        <input onKeyUp={this.onSearchProducts} onKeyDown={this._handleKeyDown} className='search-input' placeholder="Search for products"/>
                        {this.state.loading &&<div style={{height:20,width:20}} className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>}
                        <SearchIcon className='m-2' onClick={this.onSearchClick}/>
                    </div>
                    <div className='location-dropdown-container'>
                        <AutoComplete  handleLocation={this.handleLocation} cities={this?.props?.cities}/>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchKeys:state.ads.searchKeys,
        cities:state.ads.cities,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSearchKeys : (searchKeys)=> {
            dispatch(getSearchKeys({searchKeys}))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

