import React, {Component} from 'react';
import './Footer.css'
import {NavLink} from "react-router-dom";
import TWITTER from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';


class Footer extends Component {
    render() {
        return (
            <div className='d-flex  flex-wrap flex-column align-items-center justify-content-center  pl-2 pr-2 footer-container'>
                <div className='d-flex align-items-center flex-wrap justify-content-center '>
                    <NavLink
                        className="navLink NunitoRegular d-flex  flex-row align-items-center cursor-pointer"
                        to="/app/contact-us">
                       Contact us
                    </NavLink>
                    <NavLink
                        className="navLink NunitoRegular d-flex  flex-row align-items-center cursor-pointer"
                        to="/app/help">
                        Help & FAQ
                    </NavLink>
                    <NavLink
                        className="navLink NunitoRegular d-flex  flex-row align-items-center cursor-pointer"
                        to="/app/about-us">
                        About us
                    </NavLink>
                    <NavLink
                        className="navLink NunitoRegular d-flex  flex-row align-items-center cursor-pointer"
                        to="/app/Privacy&Terms">
                        Privacy&Terms
                    </NavLink>
                </div>
                <div className='d-flex flex-row contactWithUs'>
                    <a  target='_blank' className="NunitoRegular d-flex  flex-row align-items-center cursor-pointer" href={'https://twitter.com/Justplay_today'}><TWITTER/></a>
                    <a  target='_blank' className="NunitoRegular d-flex  flex-row align-items-center cursor-pointer" href={'https://www.instagram.com/justplaytoday_official/'}><Instagram /></a>

                </div>

                {/*<div className='d-flex align-items-center'>*/}
                {/*    <div className="navLink NunitoRegular cursor-pointer">*/}
                {/*        <div>*/}
                {/*            <a className='link' href={'https://twitter.com/Justplay_today'}>Instagram</a>*/}
                {/*            <a className='link' href={'https://www.instagram.com/justplaytoday_official/'}>Facebook</a>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*</div>*/}

                <p className='copyright'>© Copyright 2021.  JustPlayToday.com</p>
            </div>
        );
    }
}

export default Footer;
