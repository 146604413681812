import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './global.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import createSagaMiddleware from "redux-saga"
import "bootstrap/scss/bootstrap.scss"
import logger from "redux-logger"
import { Provider } from "react-redux"
import rootSaga from "./sagas"
import reducer from "./reducers"
import { applyMiddleware, compose, createStore } from "redux"
import { BrowserRouter } from "react-router-dom"
import { createBrowserHistory } from "history"

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);


const app = (
    <Provider store={store}>
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(
  <React.StrictMode>
      {app}
  </React.StrictMode>,
  document.getElementById('root')
);
// ReactDOM.render(app, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
