import React, { Component } from "react"
import "./CarouselView.scss"
import Next from '@material-ui/icons/NavigateNext';
import Previous from '@material-ui/icons/NavigateBefore';
import { connect } from "react-redux"

class CarouselView extends Component {

  state = {
    counter: 0,
    images:[]
  };

  componentDidMount = () => {
    console.log("componentDidMount")
      this.setState({images:this.props.images})
      console.log("images",this.props.images)
  };

  componentWillReceiveProps(nextProps, nextContext) {
    // this.setState({images:[]},function () {
      this.setState({images:[...nextProps.images]})
      console.log("images",nextProps.images)
    // })
  }

  handleNavClick = (dir) => {
    if(dir === -1){
      if(this.state.counter === 0 ){
        this.setState({counter:this.state.images.length-1})
      }
      else{
        this.setState({counter:this.state.counter -1})
      }
    }
    else {
      if(this.state.counter === this.state.images.length-1){
        this.setState({counter:0})
      }
      else{
        this.setState({counter:this.state.counter +1})
      }
    }
  };

  render() {
console.log("images",this.props)
    const {counter,images} = this.state;

    return (
        <div className="carousel-wrapper">
          {/*<div className='d-flex flex-column'><p>{images.length}</p></div>*/}
          <div className="d-flex justify-content-between carousel-container">
            <div style={{visibility:images.length >1 ?"visible" :"hidden"}} className="leftArrow d-flex align-items-center justify-content-center  ">
              <Previous  className='nav-icons' width={30} height={30} onClick={()=>this.handleNavClick(-1)}/>
            </div>
            <div className="carousal-image-container d-flex flex-column align-items-center align-self-center">
              <img className="carousal-image" src={images[counter]}/>
            </div>
            <div style={{visibility:images.length >1 ?"visible" :"hidden"}} className="rightArrow d-flex align-items-center justify-content-center ">
              <Next  className='nav-icons'onClick={()=>this.handleNavClick(1)}/>
            </div>
          </div>
        </div>
    );
  }
}


function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(CarouselView);
