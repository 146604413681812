import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyDMhYA6CEHW9xZ41_1EV5k1EBaT03WtLB8",
    authDomain: "justplaytoday-99061.firebaseapp.com",
    databaseURL: "https://justplaytoday-99061.firebaseio.com",
    projectId: "justplaytoday-99061",
    storageBucket: "justplaytoday-99061.appspot.com",
    messagingSenderId: "251978666448",
    appId: "1:251978666448:web:20d0492e6625f5976da9d5",
    measurementId: "G-8CYWFTYCD9"
};

firebase.initializeApp(firebaseConfig);

export let fireabase;
export let defaultStorage = firebase.storage();
export let  defaultFirestore = firebase.firestore();
export let  defaultDatabase = firebase.database();
export let  defaultAuth = firebase.auth();
export let  defaultAnalytics = firebase.analytics();

