import React, {Component} from 'react';
import RecentMessageItem from "./RecentMessageItem";
import './RecentMessageList.scss'

class RecentMessageList extends Component {

    getMessageItem = (chat, index) => {

            return <div onClick={()=>{
                this.props.onRecentMessageClick(chat)}} ><RecentMessageItem  key={index} current_message_user_id={this.props.current_message_user_id}  chat={chat}/></div>
    };

    componentDidMount() {
        console.log("RecentMessageList >>>",this.props)
    }

    render() {
        return (
            <div>
                <div className="messageSearch-bodyWrapper">
                    <div className="messageSearch-body pt-4">
                        <div className="">
                            <div className='d-flex flex-row align-self-start justify-content-start'><label
                                className="chats-heading">Chats</label></div>
                            {/*<input*/}
                            {/*    onChange={(event)=> this.setState({searchText:event.target.value})}*/}
                            {/*    className="messageSearch-input NunitoRegular mb-2"*/}
                            {/*    type="input"*/}
                            {/*    value={this.state.searchText}*/}
                            {/*    placeholder="Search by name..."*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        {/*{this.getMessageItem()}*/}
                        {this.props.recentChats && this.props.recentChats.length > 0 ? this.props.recentChats.map((chat, index) => {
                                {
                                    return this.getMessageItem(chat, index)
                                }
                            })
                            : null
                        }
                    </div>
                    <hr className='line'></hr>
                </div>
            </div>
        );
    }
}

export default RecentMessageList;
