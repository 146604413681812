import {
  defaultDatabase,
  defaultFirestore,
  defaultStorage,
} from "../Config/firebaseConfig";

export const createAdService = (payload) => {
  defaultDatabase
      .ref("searchKeys/")
      .push()
      .set(payload.ad.title.toString().toLowerCase());
  defaultFirestore
      .collection("ads")
      .doc(payload.ad_id)
      .set(payload.ad)
      .then(function () {
        console
            .log("post created service success");
        return true;
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        return false;
      });
};

export const getAdsService = (payload) => {
  console.log("Search payload >>>>", payload);
  let adsRef;
  debugger;

  if (payload.location) {
    if (payload.searchText) {
      debugger;
      adsRef = defaultFirestore
          .collection("ads")
          .where("city", "==", `${payload.location}`)
          .where("title", "==", `${payload.searchText}`)
          .limit(100);
    } else if (payload.category) {
      adsRef = defaultFirestore
          .collection("ads")
          .where("category", "==", `${payload.category}`)
          .limit(100);
    } else if (payload.subcategory) {
      adsRef = defaultFirestore
          .collection("ads")
          .where("subCategory", "==", `${payload.subcategory}`)
          .limit(100);
    } else {
      adsRef = defaultFirestore
          .collection("ads")
          .where("city", "==", `${payload.location}`)
          .limit(100);
    }
  } else if (payload.searchText) {
    adsRef = defaultFirestore
        .collection("ads")
        .where("title", "==", `${payload.searchText}`)
        .limit(100);
  } else if (payload.category) {
    adsRef = defaultFirestore
        .collection("ads")
        .where("category", "==", `${payload.category}`)
        .limit(100);
  } else if (payload.subcategory) {
    adsRef = defaultFirestore
        .collection("ads")
        .where("subCategory", "==", `${payload.subcategory}`)
        .limit(100);
  } else {
    adsRef = defaultFirestore.collection("ads")
        .limit(100);
  }
  return adsRef.get();
};

export const getFreshRecommendationsService = (payload) => {
  debugger
  let adsRef;
  if (payload.location) {
    adsRef = defaultFirestore
        .collection("ads")
        .where("isProductAvailable", "==", true)
        .where("city", "==", `${payload.location}`).orderBy("created_at","desc")
        .limit(100);
  } else {
    if(!payload.lastVisible) {
      adsRef = defaultFirestore.collection("ads").limit(8).orderBy("created_at", "desc");
    }
    else{
      adsRef = defaultFirestore.collection('ads').orderBy("created_at", "desc")
            .startAfter(payload.lastVisible.data().created_at).limit(8);
    }
    // var first = defaultFirestore.collection('ads').orderBy("created_at", "desc").limit(3);
    // console.log("FIRST QUERY!");
    // first.get().then(function(documentSnapshots) {
    //   documentSnapshots.forEach(function (doc) {
    //     console.log(doc.data())
    //   });
    //   // Get the last visible document
    //   var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    //   console.log("lastVisibleNew",lastVisible);
    //
    //   var next = defaultFirestore.collection('ads').orderBy("created_at", "desc")
    //       .startAfter(lastVisible.data().created_at).limit(3);
    //   next.get().then(function (docSn) {
    //     console.log("SECOND QUERY!");
    //     docSn.forEach(function (doc) {
    //       console.log(doc.data())
    //     });
    //   });
    // });
  }
  return adsRef.get();
};

export const searchAdsService = (payload) => {
  let adRef;
  console.log(payload);
  debugger;
  if (payload.location && payload.location !== "All") {
    if (payload.searchKeys && payload.searchKeys.length > 0) {
      adRef = defaultFirestore
          .collection("ads")
          .where("city", "==", payload.location)
          .where("keyArray", "array-contains-any", [...payload.searchKeys])
          .limit(30);
    } else if(payload.searchText) {
      adRef = null
    }
    else{
      adRef = defaultFirestore
          .collection("ads")
          .where("city", "==", payload.location)
          .limit(30);
    }
  } else if (payload.searchKeys && payload.searchKeys.length > 0) {
    adRef = defaultFirestore
        .collection("ads")
        .where("keyArray", "array-contains-any", [...payload.searchKeys])
        .limit(30);
  }else if(payload.searchText) {
    adRef = null
  }else {
    adRef = defaultFirestore.collection("ads").where("isProductAvailable", "==", true).limit(100);
  }

  if(adRef !== null) {
    return adRef.get();
  }
  else {return  null}
};

export const getAdService = (payload) => {
  let adRef = defaultFirestore.collection("ads").doc(payload.adId);
  return adRef.get();
};

export const getCategoriesService = () => {
  let categoriesRef = defaultFirestore
      .collection("Categories")
      .doc("RxY3OxQbKZp7CoVe8ONx");
  return categoriesRef.get();
};

export const uploadAdMediaService = (payload) => {
  let adMediaRef = defaultStorage.child(payload.ad_id);
  adMediaRef.put(payload.file).then(function (snapshot) {
    return snapshot;
  });
};
