import React, {Component} from 'react';
import styles from './HelpAndContact.css'
import helpData from "./helpDatanew";
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDown from '@material-ui/icons/ArrowDropDown';


class HelpAndContact extends Component {

    constructor(props, context) {
        super(props);
        this.state={
            activeId:-1
        }
    }

    render() {
        const {activeId} = this.state
        return (
            <div className='help' style={{paddingTop:60}}>
                Frequently asked questions : FAQ
                {helpData.faq.map(faq => <div style={{margin:2}}>
                    <div onClick={()=> this.setState({activeId:(activeId !== faq.id ? faq.id : -1)})} className="accordion">
                        {activeId !== faq.id ? <ArrowRight /> : <ArrowDown/>}
                        {faq.question}
                    </div>
                    <div className={activeId !== faq.id ? 'panel' :'active'}>
                        <pre style={{fontFamily:'Nunito-SemiBold',fontSize:'16px'}}>{faq.answer}</pre>
                    </div>
                </div>)}
                Website Help
                {helpData.websiteHelp.map(faq => <div style={{margin:2}}>
                    <div onClick={()=> this.setState({activeId:(activeId !== faq.id ? faq.id : -1)})} className="accordion">
                        {activeId !== faq.id ? <ArrowRight /> : <ArrowDown/>}
                        {faq.question}
                    </div>
                    <div className={activeId !== faq.id ? 'panel' :'active'}>
                        <pre>{faq.answer}</pre>
                    </div>
                </div>)}
            </div>
        );
    }
}

export default HelpAndContact;
