import React, {Component} from 'react';
import {defaultDatabase, defaultFirestore} from "../../Config/firebaseConfig";
import AdCard from "../../components/AdCard/AdCard";
import {toastFeedback} from "../../Toast/ToastFunctions";
import * as firebase from "firebase";
import Footer from "../../components/Footer/Footer";
import './Favorites.css'

class Favorites extends Component {

    state = {
        favorites :[],
        favIdList:[]
    };

    getUserFavourites = () => {
        const  self = this;
        if (localStorage.getItem('uid')) {
            let docRef = defaultFirestore.collection("userFavouriteIdList").doc(localStorage.getItem('uid'));
            docRef.get().then(function (doc) {
                if (doc.exists) {
                    debugger
                    let favourites = [...doc.data().favourites];
                    self.setState({favIdList: favourites}, function () {
                    })
                } else {
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
        }
    };

    addToFavourites = async (ad) => {
        const self = this;
        let isRemove = false;
        let fav = this.state.favIdList;
        isRemove = fav.includes(ad.id);
        if (isRemove) {
            fav = fav.filter(id => id !== ad.id)
        } else {
            fav.push(ad.id)
        }

        console.log('isRemove',fav);
        let keySet = new Set([...fav]);

        if (!isRemove){
            defaultDatabase
                .ref("users/").child(localStorage.getItem('uid')).child("favourites").child(ad.id)
                .set({
                    title: ad.title,
                    description: ad.description,
                    media: ad.media,
                    id: ad.id,
                    price:ad.price,
                    city:ad.city,
                    created_at:ad.created_at,
                    condition:ad.condition,
                    isProductAvailable: ad.isProductAvailable,
                });
        }
        else{
            let ref = defaultDatabase.ref('users/')
                .child(localStorage.getItem('uid')).child('favourites').child(ad.id);
            ref.remove().then(data=>{
                console.log('Deleted data')

            });
        }

        defaultFirestore
            .collection("userFavouriteIdList")
            .doc(localStorage.getItem('uid'))
            .set({favourites: keySet?.size !==0 ? firebase.firestore.FieldValue?.arrayUnion(...keySet) : []})
            .then( function () {
                if (isRemove) {
                    toastFeedback("Remove from favourites");

                } else {
                    toastFeedback("Added to favourites");
                }
                self.getUserFavourites();
                self.getFavList();
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });

    };


    getFavList = () => {
        let favList = [];
        const  self = this
        console.log('ocalStorage.getItem(\'uid\')',localStorage.getItem('uid'))
        defaultDatabase.ref('users/').child(localStorage.getItem('uid')).child('favourites').once('value',   function(snapshot) {
            snapshot.forEach(function(childSnapshot) {
                favList.push(childSnapshot.val());
            });
            console.log(favList);
            self.setState({favorites:favList});
        });
        console.log('Fav list',favList)
    };

    componentDidMount () {
        this.getUserFavourites()
        this.getFavList();
    }



    render() {
        const {favorites} = this.state
        return (
            <div className=''>
            <div>
                <h3 className='ml-3 align-items-center d-flex justify-content-center w-100' style={{paddingTop:70}}>Favorites</h3>
                <div className='row wrapper' >
                    { favorites.map((ad) => {
                        return  <div key={ad.id}   className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="d-flex justify-content-center align-items-center">
                                <AdCard isFav={true} addToFavourites={this.addToFavourites} history={this.props.history}  ad={ad}/>
                            </div>
                        </div>
                    })}
                </div>
            </div>
                <Footer/>
            </div>

        );
    }
}

export default Favorites;
