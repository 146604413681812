import toaster from "toasted-notes"
import React from "react"
import Close from '@material-ui/icons/HighlightOff';
//
// import close from "../../Assets/icons/times-black.svg";
// import user from "../../Assets/icons/user-avatar-gray.png"
// import success from "../../Assets/icons/check-circle-fill.svg"
import './ToastFunctions.scss'

export const toastMessage = ({message,history}) => {
  return  toaster.notify(() => (
    <div className='custom-toast-container pb-1' onClick={()=> {
      toaster.removeAll()
      history.push("/app/messages")}}>
      <div className='custom-toast-header d-flex flex-row justify-content-center align-items-center'>
        <p className='custom-toast-header-text'>NEW MESSAGE</p>
        <div className='spacer'/>
        <div className='d-flex flex-row'>
          <p className="custom-toast-time">now</p>
          <Close className='custom-toast-close' onClick={(e)=>{ e.stopPropagation();toaster.removeAll()}} />
        </div>
      </div>
      <div className='custom-toast-content d-flex flex-row'>
        {/*<img className='user-avatar-small mr-2' src={user}/>*/}
        <div>
          <p className='custom-toast-content-heading'>You have a new message</p>
          <div className="block-ellipsis">
            {message.content}
          </div>
        </div>
      </div>
    </div>
  ),{position:"bottom-right",duration:null});
}

export const toastFeedback = (feedbackMessage) => {
  return  toaster.notify(() => (
    <div className='d-flex justify-content-between  align-items-center toast-feedback-container'>
      {/*<img className='success-icon' src={success}></img>*/}
        <p className='mb-0'>{feedbackMessage}</p>
      <div>
        <Close className='custom-toast-close' onClick={(e)=>{ e.stopPropagation();toaster.removeAll()}} />
      </div>
    </div>
  ),{position:"bottom-right",duration:5000});
}

export const toastNotification = (({notification,history}) => {

  const onToastClick = () => {
    switch (notification.sub_type) {
      case "NEW_CONNECTION_REQUEST" : {
        history.push("/app/connections/received")
        break
      }
      case "CONNECTION_ACCEPTED" : {
        history.push("/app/connections/my-connections")
        break
      }
      case "ADDED_TO_COLLECTION" : {
        history.push("/app/memories/collections")
        break
      }
      case "COMMENT_ON_MEMORY" : {
        if(notification && notification.content)
          history.push(`/collection/${notification.content.collection_id}/memory/${notification.content.memory_id}`)
        break
      }
      case "COMMENT_IN_COLLECTION" : {
        if(notification && notification.content)
          history.push(`/collection/${notification.content.collection_id}/memory/${notification.content.memory_id}`)
        break
      }
      case "NEW_MEMBER_IN_COLLECTION" : {
        history.push("/app/memories/collections")
        break
      }
      case "MEMORY_IN_COLLECTION" : {
        history.push("/app/memories/collections")
        break
      }
      case "REPLY_ON_COMMENT" : {
        if(notification && notification.content && notification.content.collection_id)
          history.push(`/collection/${notification.content.collection_id}/memory/${notification.content.memory_id}`)
        break
      }
      case "TRIAL_ABOUT_TO_EXPIRE" : {
        history.push("/app/account-settings")
        break
      }
      case "SUBSCRIPTION_ABOUT_TO_EXPIRE_30" : {
        history.push("/app/account-settings")
        break
      }
      case "SUBSCRIPTION_ABOUT_TO_EXPIRE_14" : {
        history.push("/app/account-settings")
        break
      }
      case "SUBSCRIPTION_ABOUT_TO_EXPIRE_7" : {
        history.push("/app/account-settings")
        break
      }
    }
  };

  return toaster.notify(() => (
    <div className='custom-toast-container' onClick={onToastClick}>
      <div className='custom-toast-header d-flex flex-row justify-content-center align-items-center'>
        <p className='custom-toast-header-text'>NEW NOTIFICATION</p>
        <div className='spacer'/>
        <div className='d-flex flex-row'>
          <p className="custom-toast-time">now</p>
          <Close className='custom-toast-close' onClick={(e)=>{ e.stopPropagation();toaster.removeAll()}}/>
        </div>
      </div>
      <div className='custom-toast-content d-flex flex-row'>
        {/*<img className='user-avatar-toast mr-2' src={notification.content.profile_image_thumbnail_url}/>*/}
        <div className='d-flex align-items-center justify-content-center'>
          <div className="block-ellipsis">
            {notification.subject}
          </div>
        </div>
      </div>
    </div>
  ),{position:"bottom-right",duration:5000});
})

