import React from "react"
import PropTypes from "prop-types"

const styles = {
  header: {
    backgroundColor: "rgb(19,25,33)",
    color: "white",
    height: "56px",
    padding: "0px 5px",
    position:"fixed",
    width:"100%",
    zIndex:'2',
  }
};

const MaterialTitlePanel = (props) => {
  const rootStyle = props.style ? { ...styles.root, ...props.style } : styles.root

  return (
    <div style={rootStyle}>
      <div style={styles.header}>{props.title}</div>
      {props.children}
    </div>
  )
};

MaterialTitlePanel.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.object
}

export default MaterialTitlePanel
