import React, {Component} from 'react';
import './Dashboard.scss'
import AdCard from "../../../components/AdCard/AdCard";
import {
    createAd,
    getCategories,
    getFreshRecommendations,
    searchAds,
} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {CATEGORIES, CITIES, GET_ADS_SUCCESS, SUBCATEGORIES} from "../../../actions/constants";
import {defaultAuth, defaultDatabase, defaultFirestore} from "../../../Config/firebaseConfig";
import * as firebase from "firebase";
import {toastFeedback} from "../../../Toast/ToastFunctions";
import Footer from "../../../components/Footer/Footer";

class Dashboard extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isSearch : true,
            searchText:"",
            location:"",
            titleKeyMatches : [],
            isTitleSearch :false,
            searchResults:[],
            favIdList :[],
            isSearchResultsEmpty:false,
            totalProdCount:0
        }
    }

    componentDidMount = async() =>  {
        debugger
        this.props.getAdsSuccess();
        const self = this;
        defaultFirestore.collection("ads").get().then(function(querySnapshot) {
            console.log('count',querySnapshot.size);
            self.setState({totalProdCount:querySnapshot.size})
        });

        this.props.getAdsSuccess();

        this.props.getFreshRecommendations(null);
        this.getUserFavourites()
    };

    onLoadMore = () =>{
        this.props.getFreshRecommendations(this.props.lastVisible);
    };

    getUserFavourites = () => {
        const  self = this;
        if (localStorage.getItem('uid')) {
            let docRef = defaultFirestore.collection("userFavouriteIdList").doc(localStorage.getItem('uid'));
            docRef.get().then(function (doc) {
                if (doc.exists) {
                    debugger
                    let favourites = [...doc.data().favourites];
                    self.setState({favIdList: favourites}, function () {
                    })
                } else {
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
        }
    };

    addToFavourites = async (ad) => {
        if(this.props.user && this.props.user.uid ){
            const self = this;
            let isRemove = false;
            let fav = this.state.favIdList;
            isRemove = fav.includes(ad.id);
            if (isRemove) {
                fav = fav.filter(id => id !== ad.id)
            } else {
                fav.push(ad.id)
            }

            console.log('isRemove',fav);
            let keySet = new Set([...fav]);

            if (!isRemove){
                defaultDatabase
                    .ref("users/").child(localStorage.getItem('uid')).child("favourites").child(ad.id)
                    .set({
                        title: ad.title,
                        description: ad.description,
                        media: ad.media,
                        id: ad.id,
                        price:ad.price,
                        city:ad.city,
                        created_at:ad.created_at,
                        condition:ad.condition,
                        isProductAvailable: ad.isProductAvailable,
                    });
            }
            else{
                let ref = defaultDatabase.ref('users/')
                    .child(localStorage.getItem('uid')).child('favourites').child(ad.id);
                ref.remove().then(data=>{
                    console.log('Deleted data')

                });
            }

            defaultFirestore
                .collection("userFavouriteIdList")
                .doc(localStorage.getItem('uid'))
                .set({favourites: keySet?.size !==0 ? firebase.firestore.FieldValue?.arrayUnion(...keySet) : []})
                .then( function () {
                    if (isRemove) {
                        toastFeedback("Remove from favourites");

                    } else {
                        toastFeedback("Added to favourites");
                    }
                    self.getUserFavourites();
                })
                .catch(function (error) {
                    console.error("Error writing document: ", error);
                });
        }
        else {
            toastFeedback("Please login to add to favorites")
        }
    };

    onSearch = (searchText) => {
        const {location,category,subcategory} = this.state;
        const self = this;

        console.log("search",searchText,location);

        this.setState({searchText:searchText},function () {
            debugger;
            if(searchText) {
                let searchKeys;
                let titleMatches = this.props.searchKeys.filter(item => item.replace(" ", "").toLowerCase().includes(searchText.replace(" ", "").toLowerCase()));
                searchKeys = [...titleMatches];
                // if (titleMatches.length === 0) {
                    let locationMatch = this.props.cities.find(item => item.toLowerCase().includes(searchText.toLowerCase()));
                    searchKeys.push(locationMatch);
                // }
                // if (titleMatches.length === 0) {
                    let categoryMatch = CATEGORIES.find(item => item.toLowerCase().includes(searchText.toLowerCase()));
                    searchKeys.push(categoryMatch);
                // }
                // if (titleMatches.length === 0) {
                    let subCategoryMatch = SUBCATEGORIES.find(item => item.toLowerCase().includes(searchText.toLowerCase()));
                    searchKeys.push(subCategoryMatch);
                // }

                let searchKeysSet = new Set([...searchKeys]);
                let newKeys = [...searchKeysSet];
                newKeys = newKeys.filter(item => {
                    return item !== undefined && item !== "";
                });
                self.props.searchAds(newKeys, location, "", "", self.onSearchResults,searchText)
            }
            else{
                self.props.searchAds([], location, "", "", self.onSearchResults,searchText)
            }
        });
    };

    onLocationSelect = (location,searchText) => {
        const self = this;
        this.setState({location:location},function () {
            self.onSearch(self.state.searchText)
        });
    };

    onSearchResults = (results) =>{
        if(results?.length === 0){
            this.setState({isSearchResultsEmpty:true})
        }
        else{
            this.setState({isSearchResultsEmpty:false})
        }
    };

    render() {
        const ads = this.props.ads;
        const {location,searchText,totalProdCount} = this.state;
        const searchedAds =  this.props.searchedAds;
        return (
            <div className='dashboard_main'>
                <div>
                    <SearchBar onSearch={this.onSearch} onLocationSelect={this.onLocationSelect} />
                </div>
                <div className='dashboard-recommendations p-0 m-auto'>
                    <div className='d-flex flex-row justify-content-start align-items-start mt-4 mb-4' style={{color:"black"}}><h3>Most Recent Products</h3></div>
                    {totalProdCount ===0  && <div className='d-flex align-items-center justify-content-center'>No products found !</div>}
                    <div className="row">
                        { (!searchedAds || searchedAds.length === 0 )  ?
                            ads.map((ad) => {
                                return ((ad.owner !== localStorage.getItem('uid')) && ad.isProductAvailable ) && <div key={ad.id}   className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                     <div className="d-flex justify-content-center align-items-center">
                                        <AdCard isFav={this.state.favIdList.includes(ad.id)} addToFavourites={this.addToFavourites} history={this.props.history}  ad={ad}/>
                                    </div>
                                </div>
                            })
                            :
                            (searchedAds && searchedAds.length) > 0 && searchedAds.map((ad) => {
                                return ((ad.owner !== localStorage.getItem('uid')) && ad.isProductAvailable ) && <div key={ad.id}
                                                                                        className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <AdCard  isFav={this.state.favIdList.includes(ad.id)} addToFavourites={this.addToFavourites} history={this.props.history}  ad={ad}/>
                                    </div>
                                </div>
                            })
                        }
                        {this.state.isSearchResultsEmpty && <div className='w-100 mt-5 d-flex flex-row align-items-center justify-content-center'><p>No results found!!</p></div>}

                    </ div>
                </div>
                {(!location && !searchText && totalProdCount > ads.length ) && <div className={'d-flex flex-row' +
                ' align-items-center' +
                ' justify-content-center w-100 dashboard'}><button className={'loadMore'} onClick={this.onLoadMore}>LoadMore</button></div>}
                <Footer className='footer'></Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        categories:state.ads.categories,
        ads:state.ads.ads,
        lastVisible:state.ads.lastVisible,
        cities:state.ads.cities,
        searchKeys:state.ads.searchKeys,
        searchedAds:state.ads.searchAds,
        user:state.ads.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAd : (ad,ad_id,onCreateSuccess)=> {
            // dispatch(createAd({ad:ad,ad_id:ad_id,onCreateSuccess}))
        },
        getCategories : () => {
            dispatch(getCategories({}))
        },
        searchAds : (searchKeys,location,category,subCategory,onSearchResults,searchText) => {
            dispatch(searchAds({searchKeys,location,category,subCategory,onSearchResults,searchText}))
        },
        getFreshRecommendations : (lastVisible) => {
            dispatch(getFreshRecommendations({lastVisible}))
        },
        getAdsSuccess : () => {
            dispatch({type: GET_ADS_SUCCESS,ads:[]})
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)


