export function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

export const addDefaultSrc = (ev) => {
    ev.target.src = 'https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg'
};

export const addProfileDefaultSrc = (ev) =>{
    ev.target.src = "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
};

export const  convertUTCToLocalTime = (timeInUTC) => {
    let dateInLocal = Date.parse(timeInUTC);
    let dateInLocalDate = new Date(dateInLocal);
    let month = dateInLocalDate.getMonth();
    let day = dateInLocalDate.getDate();
    let time = new Date(timeInUTC).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    // return day + " "+ monthList[month].substring(0,3).toUpperCase() +" " +time;
};

export const convertMillisToLocalTime = (timeInMillis) =>{
    let date = new Date(timeInMillis);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (months[date.getUTCMonth()] + " " + date.getUTCDate() +" " +date.getUTCFullYear()  )
};

export function blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = "file_name";
    return theBlob;
}


