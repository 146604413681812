import {
    CREATE_AD, CURRENT_SEARCH_TITLE_KEYS,
    DELETE_AD,
    GET_AD,
    GET_ADS,
    GET_CATEGORIES, GET_FRESH_RECOMMENDATIONS, GET_KEYS,
    GET_KEYS_SUCCESS,
    SAVE_USER_PROFILE, SEARCH_ADS,
    UPDATE_Ad, UPDATE_CITIES
} from "./constants";


export function saveUserProfile(payload) {
    return {
        type: SAVE_USER_PROFILE,
        payload: payload
    }
}

export function createAd(payload) {
    return {
        type: CREATE_AD,
        payload: payload
    }
}

export function deleteAd(payload) {
    return {
        type: DELETE_AD,
        payload: payload
    }
}

export function getCategories(payload) {
    return {
        type: GET_CATEGORIES,
        payload: payload
    }
}

export function getSearchKeys(payload) {
    return {
        type: GET_KEYS_SUCCESS,
        payload: payload
    }
}

export function getAds(payload) {
    return {
        type: GET_ADS,
        payload: payload
    }
}

export function getFreshRecommendations(payload) {
    return {
        type: GET_FRESH_RECOMMENDATIONS,
        payload: payload
    }
}

export function searchAds(payload) {
    debugger
    return {
        type: SEARCH_ADS,
        payload: payload
    }
}


export function getAd(payload) {
    return {
        type: GET_AD,
        payload: payload
    }
}

export function updateAd(payload) {
    return {
        type: UPDATE_Ad,
        payload: payload
    }
}

export function updateCities(payload) {
    return {
        type: UPDATE_CITIES,
        payload: payload
    }
}


