let helpData;
export default helpData = {
  "faq":[ {
    id:1,
    question: "What is Justplaytoday.com?",
    answer: "Through Justplaytoday.com, one can sell their used/unused music instruments and accessories to people who are looking for it. \n" +
        "It’s convenient to use and absolutely free. One can also search to buy a used/unused product in one’s location."
  },
    {
      id:2,
      question: "How does this website work?",
      answer: "Justplaytoday.com is a place where users can buy and sell music instruments and, accessories and" +
          " music related products.\n\nSELLING: You can easily within minutes post an ad of your music related" +
          " instrument product after you have logged in.\n\n" +
          "Buying: You get to see a host of products in your area with all the information about the product. " +
          "Then you just contact the seller and buy directly from them."

    },
    {
      id:3,
      question: "Do I have to pay Justplaytoday.com to buy or sell a product through them?",
      answer: "Not at all! You can post your ad or even contact sellers without having to pay a single pie. Just make sure you are logged in."

    },
    {
      id:4,
      question: "Are the sellers verified? ",
      answer: "Basic email verification is done while registering.\n"

    },
    {
      id:5,
      question: "Do I have to publicly display my phone number?",
      answer: "It is at your discretion. Even if you don’t want to give out your number,\n we have an in-app messaging system which cater to your needs for products that you want to buy or sell."

    },
    {
      id:6,
      question: "What more does the website do?\n",
      answer: "Other than what we offer right now, that is, giving you a place to sell and buy music instruments \n" +
          "and accessories conveniently. We are working hard to bring up a lot of different features such as classifieds,\n " +
          "blogs and articles and other helpful musician related products. Check About us to know more.\n"

    },
    {
      id:7,
      question: "Does just play share user information?\n",
      answer: "No Justplaytoday.com does not share user information."

    },
  ],
  websiteHelp:[
    {
      id:8,
      question: "How to sign up and log-in into your account?\n",
      answer: "Mobile version:\n" +
          "Sign up:\n" +
          "Open Justplaytoday.com.\n" +
          "Click the menu bar.\n" +
          "In the drop-down menu click on the sign-up button\n" +
          "The sign-up form will be displayed on the page.\n" +
          "You can create an account in Justplaytoday.com or sign up through gmail.com\n" +
          "Enter the relevant information on the Justplaytoday.com sign up page.\n" +
          "Just verify the account via the verification code sent to your e-mail.\n" +
          "You are ready to use Justplaytoday.com\n" +
          "(we will be out with an app soon)\n" +
          "" +
          "Log in:\n" +
          "Open Justplaytoday.com.\n" +
          "Click the menu bar.\n" +
          "In the drop-down menu click on the login button\n" +
          "Enter your credentials used while signing up and you are good to go.\n" +
          "" +
          "" +
          "Laptop Version:\n" +
          "Sign up:\n" +
          "Open Justplaytoday.com\n" +
          "On the navigation bar click on login/register.\n" +
          "The sign-up form will be displayed on the page.\n" +
          "You can create an account in Justplaytoday.com or sign up through gmail.com\n" +
          "Enter the relevant information on the Justplaytoday.com sign up page.\n" +
          "Just verify the account via the verification code sent to your e-mail.\n" +
          "You are ready to use Justplaytoday.com\n" +
          "" +
          "Login:\n" +
          "Open Justplaytoday.com\n" +
          "On the navigation bar click on login/register.\n" +
          "Enter your credentials used while signing up and you are good to go.\n"
    },
    {
      id:9,
      question: "How to fill in the sign-up form?",
      answer: "The sign-up page is very intuitive. It’s just a form wherein you have to add mandatory details like your Name, Email address and location."

    },
    {
      id:10,
      question: "How to post my product ad?",
      answer: "Once you are logged in, click on “POST”.  In the add product page, Enter the necessary details of the musical instrument or accessory such as," +
          "Name of the product\n" +
          "Condition of the product\n" +
          "Category and sub-category your instrument or your music accessory falls under\n" +
          "Price of the music instrument or accessory that you are quoting\n" +
          "Add the description of your product and maybe why you want to sell it in your own words\n" +
          "Pictures of the music instrument or product.\n" +
          "And click on post. Voila! Its done.\n"
    },
    {
      id:11,
      question: "Help! I can’t see the instrument or accessory added by me in the list on the homepage! ",
      answer: "Yes, the products added by you will not be visible to you on the home screen. But, if you want to preview it, go to “manage ads” section and click on preview."

    },
    {
      id:12,
      question: "Manage my ads function?\n",
      answer: "Manage my ads page lets you toggle between keeping your post in the listing or turning it off" +
          " temporarily.\n This page also helps you to see the number of products you have posted and it also gives" +
          " \n" +
          " you access to previewing your music instrument or accessories that you have posted.\n"

    },
    {
      id:13,
      question: "How to Search for a product?\n",
      answer: "The Search function in Justplaytoday.com has been developed to make your searches more convenient. You can search for the music instrument or accessory you desire in two simple ways\n" +
          "" +
          "You can select the location that you want to search in which opens the list of all instruments in your area. Then you can select the category of the instrument or accessory that you want.\n" +
          "Or you can simply type in a category, an instrument name, a brand name or location and you will find results based on your search word. \n"

    },
    {
      id:14,
      question: "Where can I find my favourites list?\n",
      answer: "Once you are logged into Justplaytoday.com, Click on the drop-down menu on the task bar. This shows you a drop-down list. In this drop-down menu select favourites. This will open your favourites list page.\n"

    },
    {
      id:15,
      question: "How to check details of the music instrument or music accessory?",
      answer: "If you click on the product or click on “view details” on the product thumbnail, the details page of the music instrument or accessory will open which will give you all the information about that specific instrument or accessory. Information like,\n" +
          "" +
          "" +
          "Name\n" +
          "Brand\n" +
          "Condition\n" +
          "Year manufactured or bought\n" +
          "Images of the music product or accessory\n" +
          "And description of the music product\n" +
          "\n" +
          "Will be displayed to you.\n"

    },
    {
      id:16,
      question: "How to contact seller?\n",
      answer: "Contacting a seller is very easy. In the details page, you can write a message to the seller" +
          " regarding his instrument or accessory and he will get back to you through messages which will be displayed in your messages page.\n\n" +
          "" +
          "If the seller has displayed his/her phone number, you can directly call or message him/her.\n" +
          ""

    },
    {
      id:17,
      question: "How does messaging/chat work in Justplaytoday.com?",
      answer: "Messaging/chat in Justplaytoday.com is intuitive. You will get a notification if you receive a message from a prospective buyer. If you click on it, the messaging/chat room opens up.\n"

    },
    {
      id:18,
      question: "How to edit added product?",
      answer: "Click on the dropdown menu. Select “manage my ads”. You will see options to view your product, edit and delete your product.\n\n"

    },
    {
      id:19,
      question: "Password reset.",
      answer: "When you are at the log-in/sign-in page, click on “forgot password”. A link will be sent to your registered mail address through which you can change your password.\n"

    },
    {
      id:20,
      question: "How to delete the product",
      answer: "Click on the dropdown menu. Select “manage my ads”. You will see options to view your product, edit and delete your product.\n"

    },
    {
      id:21,
      question: "How do I share an ad in other social platforms?\n",
      answer: "Phone : Click on the share button in the product page. You can choose an option to share through link or Instagram.\n\n" +
          "" +
          "PC: Click on the share button in the product page. You can choose an option to share through link or Gmail.\n"

    },
  ]
};
