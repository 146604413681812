import React, {Component} from 'react';
import Add from '@material-ui/icons/Add';
import './UploadMedia.scss'
import InputFiles from 'react-input-files';
import {defaultStorage} from "../../../Config/firebaseConfig";
import * as firebase from "firebase";
import Image from '@material-ui/icons/CropOriginal';
import Cancel from '@material-ui/icons/Cancel';

class UploadMedia extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            ad_id:"",
            mediaFiles:[],
            isUploading:false
        }
    }

    onImageDelete = (mediaFile) => {
        const self = this;
        let mediaRef = defaultStorage.ref("ads").child(this.state.ad_id).child(mediaFile.file.name);
        mediaRef.delete().then(function() {
            let mediaFilesCopy = self.state.mediaFiles;
            let mediaIndex = mediaFilesCopy.findIndex(media => media.url === mediaFile.url);
            mediaFilesCopy.splice(mediaIndex,1);
            self.setState({mediaFiles:mediaFilesCopy},function () {
                self.props.onUploadMedia(self.state.mediaFiles)
            })
        }).catch(function(error) {
            console.log(error)
        });
    };

    onSelectImage = (files) => {
        this.props.onMediaUploading(true);
        this.setState({isUploading:true});
        const self = this;
        let mediaFiles = this.state.mediaFiles;
        let newId = this.state.mediaFiles.length;
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            mediaFiles.push({file:file,id:newId+i,progress:0});
            this.setState({mediaFiles:mediaFiles});
            let uploadTask = defaultStorage.ref("ads").child(this.state.ad_id).child(file.name).put(file, {contentType: 'image/jpeg'});
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                function(snapshot) {
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    let mediaFilesCopy = self.state.mediaFiles;
                    let media = mediaFilesCopy[newId+i];
                    media.progress = Math.round(progress);
                    mediaFilesCopy[newId+i] = media;
                    self.setState({mediaFiles:mediaFilesCopy});
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            break;
                    }
                },function(error) {console.log("Error",error)}, function() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        let mediaFilesCopy = self.state.mediaFiles;
                        let media = mediaFilesCopy[newId+i];
                        media.url = downloadURL;
                        mediaFilesCopy[newId+i] = media;
                        self.setState({mediaFiles:mediaFilesCopy},function () {
                            self.props.onUploadMedia(self.state.mediaFiles);
                            self.props.onMediaUploading(false);
                            self.setState({isUploading:false});
                        });
                    });
                });
        }
    };

    getUploadItem = (mediaFile) => {
        return <div key={mediaFile.id} className='d-flex flex-row align-items-center justify-content-center'>
            <div className='p-2 d-flex'>
                {mediaFile.progress !== 100 ?
                    <Image style={{width:80,height:80,color:"#dddddd"}} /> :
                    <img src={mediaFile.url} style={{width:80,height:80,color:"#dddddd"}}/>
                }
            </div>
            <div className='flex-grow-1 pl-2 pr-2'>
                <p className='file-name'>{mediaFile.file.name}</p>
                <div className="progress" style={{height:5}}>
                    <div className="progress-bar progress-bar-success active" role="progressbar"
                         aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{height:5,width:`${mediaFile.progress}%`}}>
                    </div>
                </div>
                <p className='m-0'>{mediaFile.progress === 100 ? "Completed": mediaFile.progress + "%"} </p>
            </div>
            <div className='d-flex flex-row align-items-start justify-content-start'><Cancel onClick={()=>this.onImageDelete(mediaFile)}/></div>
        </div>
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ad_id:nextProps.ad_id})
    }

    render() {
        return (
            <div className='w-100 mt-5'>
                <p className='d-flex align-items-center justify-content-center main-headings'>Upload images of product</p>
                <hr/>
                <div className='upload-media-layout w-100 flex-column'>
                    <div className='d-flex align-items-center justify-content-center flex-column upload-media-left-layout '>
                        <InputFiles multiple={true} onChange={this.onSelectImage}><div className='add-files-layout d-flex flex-column align-items-center justify-content-center'>
                            <div className='add-button-layout d-flex align-items-center justify-content-center'>
                                <Add style={{color:"green"}}/>
                            </div>
                            <p className='mt-3 choose-to-upload'>Choose files to upload</p>
                        </div>
                        </InputFiles>
                    </div>
                    <div className='upload-media-right-layout d-flex flex-column align-items-center justify-content-center'>
                        <div><p className='choose-to-upload text-center'>{this.state.mediaFiles.length} Files uploaded</p></div>
                        {this.state.mediaFiles && this.state.mediaFiles.map(media =>{
                            return this.getUploadItem(media)
                        })}
                    </div>
                </div>
                <hr/>
            </div>
        );
    }
}

export default UploadMedia;
