import {
    ADD_TODO,
    CREATE_AD,
    DELETE_AD, DELETE_TODO, GET_AD_SUCCESS,
    GET_ADS_SUCCESS,
    GET_CATEGORIES_SUCCESS, GET_KEYS_SUCCESS,
    SAVE_USER_PROFILE, SEARCH_ADS, SEARCH_ADS_SUCCESS,
    UPDATE_Ad, UPDATE_CITIES
} from "../actions/constants";

const initialState = {
    ads: [],
    categories: [],
    user:{},
    ad:{},
    lastVisible:null,
    searchKeys:[],
    searchAds :[],
    cities:["All","Bangalore","Chennai","Mysore","Mangalore","Hyderabad","Vishakapatnam"]
};

const adsReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case SAVE_USER_PROFILE:
            newState.user = action.payload.user;
            break;
        case GET_CATEGORIES_SUCCESS:
            newState.categories = action.categories;
            break;
        case UPDATE_Ad:
            newState.user = action.value.data;
            break;
        case DELETE_AD:
            newState.userImage = action.value.data;
            localStorage.setItem("profile_image", action.value.data)
            break;
        case GET_ADS_SUCCESS:
            if(action.data && action.data.ads) {
                newState.ads = [...newState.ads, ...action.data.ads];
                newState.lastVisible = action.data.lastVisible
            }
            else if(action?.ads){
                newState.ads = []
            }
            break;
        case SEARCH_ADS_SUCCESS:
            newState.searchAds = action.ads;
            break;
        case GET_AD_SUCCESS:
            newState.ad = {...action.ad};
            break;
        case GET_KEYS_SUCCESS:
            newState.searchKeys = action.payload.searchKeys;
            break;
        case ADD_TODO:
            return {...state,toDos:[...state.toDos,action.payload.todo]};
        case DELETE_TODO:
            return {...state,toDos:[...state.toDos.filter((id,index)=> index !== action.payload.index)]}
        case UPDATE_CITIES:
            return {...state,cities:action.payload.cities}
    }

    return newState
};


export default adsReducer
