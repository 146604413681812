import React, {Component} from 'react';
import './AdCard.scss';
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import {convertMillisToLocalTime} from "../../utils/globalFunctions";

class AdCard extends Component {

    addPostToFavorite = (event) => {
        event.stopPropagation();
        const ad = this.props.ad;
        this.props.addToFavourites(this.props.ad);
    };

    onShare = (event) => {
        event.stopPropagation();
    };

    render() {
        const {isFav,ad}= this.props;
        return (
            <div className='ad-new-card' key={ad.id} onClick={()=>this.props.history.push(`/app/${ad.id}`)}>
                {ad.media && ad.media.length >0 ? <img className='ad-image' onError={this.onDefa} src={ad.media[0]}/> :
                    <img className='ad-image' src={"https://www.motorworldindia.com/wp-content/uploads/revslider/home5/placeholder-1200x500.png"}/>}
                <div className='d-flex align-items-start pl-3 pr-3 pt-3  flex-column add-full-data'>
                    <p className='ad-name'> {ad.title}</p>
                    <p className='ad-price'> ₹ {ad.price}</p>

                    <div className='d-flex flex-row w-100 justify-content-between'>
                        <p className='ad-date'>{ad.condition}</p>
                        {/*<button className='viewMoreButton'>VIEW MORE</button>*/}
                    </div>

                    <div className='d-flex flex-row w-100 justify-content-between'>
                        <p className='ad-date'>{ad.city}</p>
                        <p className='ad-date'>{convertMillisToLocalTime(ad.created_at)}</p>
                    </div>

                    {/*<div className='d-flex w-100 align-items-center justify-content-center'><button className='viewMoreButton'>VIEW MORE</button></div>*/}
                </div>

                <div className='d-flex justify-content-between action-layout align-items-center' onClick={(event => event.stopPropagation())}>
                    <IconButton   className='d-flex align-items-start justify-content-start' aria-label="Share" onClick={(event)=>this.onShare(event)}>
                        <button onClick={()=>this.props.history.push(`/app/${ad.id}`)} style={{color:"rgb(0, 131, 202",fontSize:'14px'}}>VIEW MORE</button>
                    </IconButton>
                    {/*<IconButton aria-label="add to favorites" onClick={(event)=>this.addPostToFavorite(event)}>*/}
                        <FavoriteIcon onClick={(event)=>this.addPostToFavorite(event)} style={{color:isFav ? '#E77179' :'#dddddd'}}/>
                    {/*</IconButton>*/}

                </div>
            </div>
        );
    }
}

export default AdCard;
