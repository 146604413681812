import React, {Component} from 'react';
import './AboutUs.css'
import Footer from "../../components/Footer/Footer";

class AboutUs extends Component {
    render() {
        return (
            <div>
            <div className='d-flex align-items-center w-100 flex-column wrapper'>
                <p className='d-flex align-items-center justify-content-center aboutUsText'>About us</p>
                <p className='aboutUsDesc'>Justplaytoday.com is a one stop destination for musicians, be it professional or otherwise, to find used and unused instruments and a host of other features (which will be added very soon) with a click of a button.
                    We hope to make the lives of musicians easier and are trying to bring a lot of features under one umbrella.</p>
                    <p className='aboutUsAsst'>Any feedback would be appreciated.</p>
                    <p className='aboutUsAsst'>For assistance</p>
                    <p className='aboutUsAsst'>Email :  info@justplaytoday.com</p>
            </div>
                <Footer/>
            </div>
        );
    }
}

export default AboutUs;
